import { split} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const uri = process.env.NEXT_PUBLIC_API_URL;
const ws_uri = process.env.NEXT_PUBLIC_API_WS_URL;

const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: ws_uri as string,
        })
      )
    : null;

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem('authToken');

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

const logoutLink = onError(({ graphQLErrors }) => {
  console.error('GraphQL Error', graphQLErrors);
  if (graphQLErrors?.some((e) => e.extensions?.code === 'UNAUTHENTICATED')) {
    console.log('UNAUTHENTICATED');
    localStorage.removeItem('authToken');
    // location.href = '/signin';
  }
});


const uploadLink = createUploadLink({
  uri,
  credentials: 'include',
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        authLink.concat(uploadLink)
      )
    : authLink.concat(uploadLink);

const client = new ApolloClient({
  // @ts-ignore
  link: logoutLink.concat(splitLink),
  cache: new InMemoryCache(),
});

export default client;

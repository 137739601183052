import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  JSON: any;
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['Int'];
};

export type AddressCreateInput = {
  city: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['Int'];
};

export type AddressUpdateInput = {
  city: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['Int'];
};

export enum CartType {
  None = 'NONE',
  Notice = 'NOTICE',
  Order = 'ORDER'
}

export type Company = {
  __typename?: 'Company';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  locations?: Maybe<Array<Location>>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};

export type CompanyCreateInput = {
  name: Scalars['String'];
};

export type CompanyUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  fileUrl: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  backgroundInformation?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['JSON']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  itemCategory?: Maybe<ItemCategory>;
  menu?: Maybe<Menu>;
  name: Scalars['JSON'];
  optionGroups?: Maybe<Array<OptionGroup>>;
  optionGroupsOrder?: Maybe<Array<Scalars['String']>>;
  popularItem?: Maybe<Scalars['Boolean']>;
  posId?: Maybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  tags?: Maybe<Array<Tag>>;
};

export type ItemBackgroundInformationUpdateInput = {
  backgroundInformation: Scalars['JSON'];
  id: Scalars['String'];
};

export type ItemCategoriesInMenuUpdateInput = {
  id: Scalars['String'];
  itemAndItemCategoriesOrder: Array<Scalars['String']>;
  itemCategoryIds: Array<Scalars['String']>;
  itemIds: Array<Scalars['String']>;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Item>>;
  itemsOrder?: Maybe<Array<Scalars['String']>>;
  menu?: Maybe<Menu>;
  name: Scalars['JSON'];
};

export type ItemCategoryCreateInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  menuId?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
};

export type ItemCategoryUpdateInput = {
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  menuId?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
};

export type ItemCreateInput = {
  description?: InputMaybe<Scalars['JSON']>;
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  itemCategoryId?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
  price: Scalars['Decimal'];
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ItemOptionsUpdateInput = {
  id: Scalars['String'];
  optionGroupsOrder?: InputMaybe<Array<Scalars['String']>>;
};

export type ItemSynchResponse = {
  __typename?: 'ItemSynchResponse';
  createdItemsCount: Scalars['Int'];
  createdMenusCount: Scalars['Int'];
  updatedItemsCount: Scalars['Int'];
  updatedMenusCount: Scalars['Int'];
};

export type ItemUpdateInput = {
  description?: InputMaybe<Scalars['JSON']>;
  id: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  itemCategoryId?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
  price: Scalars['Decimal'];
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ItemsInItemCategoryUpdateInput = {
  id: Scalars['String'];
  itemIds: Array<Scalars['String']>;
  itemsOrder: Array<Scalars['String']>;
};

export type ItemsInMenuUpdateInput = {
  id: Scalars['String'];
  itemIds: Array<Scalars['String']>;
  itemsOrder: Array<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  id: Scalars['String'];
  itemCategories?: Maybe<Array<ItemCategory>>;
  items?: Maybe<Array<Item>>;
  menuCategories?: Maybe<Array<MenuCategory>>;
  menuCategoriesOrder?: Maybe<Array<Scalars['String']>>;
  menus?: Maybe<Array<Menu>>;
  name: Scalars['JSON'];
  paymentSettings?: Maybe<PaymentSettings>;
  permission?: Maybe<LocationPermissions>;
  settings?: Maybe<LocationSettings>;
  slug: Scalars['String'];
  tables?: Maybe<Array<Table>>;
  tagCategories?: Maybe<Array<TagCategory>>;
};

export type LocationCreateInput = {
  address: AddressCreateInput;
  name: Scalars['JSON'];
};

export type LocationPermissions = {
  __typename?: 'LocationPermissions';
  canAutoTranslate: Scalars['Boolean'];
  canDirectSendToPos: Scalars['Boolean'];
  cartType: CartType;
  hasPayment: Scalars['Boolean'];
  hasPos: Scalars['Boolean'];
  hasWaiterCall: Scalars['Boolean'];
  id: Scalars['String'];
};

export type LocationSettings = {
  __typename?: 'LocationSettings';
  alarmInterval?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPopularCategoryActive?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<SupportedLanguages>>;
  logo?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['JSON']>;
};

export type LocationSettingsUpdateInput = {
  alarmInterval?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  isPopularCategoryActive?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<SupportedLanguages>>;
  logo?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['JSON']>;
};

export type LocationUpdateInput = {
  address: AddressUpdateInput;
  name: Scalars['JSON'];
  slug: Scalars['String'];
};

export type Menu = {
  __typename?: 'Menu';
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  itemAndItemCategoriesOrder?: Maybe<Array<Scalars['String']>>;
  itemCategories?: Maybe<Array<ItemCategory>>;
  items?: Maybe<Array<Item>>;
  itemsOrder?: Maybe<Array<Scalars['String']>>;
  menuCategory?: Maybe<MenuCategory>;
  menuCategoryId?: Maybe<Scalars['String']>;
  name: Scalars['JSON'];
};

export type MenuCardPrintSettings = {
  __typename?: 'MenuCardPrintSettings';
  excludeItemIds?: Maybe<Array<Scalars['String']>>;
  footer: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
};

export type MenuCardPrintSettingsUpsertInput = {
  excludeItemIds?: InputMaybe<Array<Scalars['String']>>;
  footer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MenuCategoriesInLocationUpdateInput = {
  menuCategoriesOrder: Array<Scalars['String']>;
  slug: Scalars['String'];
};

export type MenuCategory = {
  __typename?: 'MenuCategory';
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  menus?: Maybe<Array<Menu>>;
  menusOrder?: Maybe<Array<Scalars['String']>>;
  name: Scalars['JSON'];
};

export type MenuCategoryCreateInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['JSON'];
};

export type MenuCategoryUpdateInput = {
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['JSON'];
};

export type MenuCreateInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  menuCategoryId?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
};

export type MenuUpdateInput = {
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  menuCategoryId?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
};

export type MenusInMenuCategoryUpdateInput = {
  id: Scalars['String'];
  menuIds: Array<Scalars['String']>;
  menusOrder: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAddress?: Maybe<Address>;
  createCompany?: Maybe<Company>;
  createItem?: Maybe<Item>;
  createItemCategory?: Maybe<ItemCategory>;
  createLocation?: Maybe<Location>;
  createMenu?: Maybe<Menu>;
  createMenuCategory?: Maybe<MenuCategory>;
  createOptionGroup?: Maybe<OptionGroup>;
  createOrder?: Maybe<Order>;
  createPayment?: Maybe<Payment>;
  createTable?: Maybe<Table>;
  createTagCategory?: Maybe<TagCategory>;
  createWaiterCall?: Maybe<WaiterCall>;
  deleteAddress?: Maybe<Address>;
  deleteCompany?: Maybe<Company>;
  deleteItem?: Maybe<Item>;
  deleteItemCategory?: Maybe<ItemCategory>;
  deleteLocation?: Maybe<Location>;
  deleteLocationSettings?: Maybe<Location>;
  deleteMenu?: Maybe<Menu>;
  deleteMenuCategory?: Maybe<MenuCategory>;
  deleteOptionGroup?: Maybe<OptionGroup>;
  deleteOrder?: Maybe<Order>;
  deleteTable?: Maybe<Table>;
  deleteTagCategory?: Maybe<TagCategory>;
  deleteWaiterCall?: Maybe<WaiterCall>;
  loginUser?: Maybe<User>;
  resetPassword?: Maybe<Scalars['String']>;
  sendOrderToPosSystem?: Maybe<Order>;
  syncItemsFromPosSystem?: Maybe<ItemSynchResponse>;
  translateMenuCard?: Maybe<Scalars['String']>;
  translateText?: Maybe<Array<Maybe<Scalars['String']>>>;
  updateAddress?: Maybe<Address>;
  updateCompany?: Maybe<Company>;
  updateItem?: Maybe<Item>;
  updateItemBackgroundInformation?: Maybe<Item>;
  updateItemCategoriesInMenu?: Maybe<Menu>;
  updateItemCategory?: Maybe<ItemCategory>;
  updateItemOptions?: Maybe<Item>;
  updateItemsInItemCategory?: Maybe<ItemCategory>;
  updateItemsInMenu?: Maybe<Menu>;
  updateLocation?: Maybe<Location>;
  updateMenu?: Maybe<Menu>;
  updateMenuCategoriesInLocation?: Maybe<Location>;
  updateMenuCategory?: Maybe<MenuCategory>;
  updateMenusInMenuCategory?: Maybe<MenuCategory>;
  updateOptionGroup?: Maybe<OptionGroup>;
  updateOrder?: Maybe<Order>;
  updateOrderItem?: Maybe<OrderItem>;
  updatePayment?: Maybe<Payment>;
  updateTable?: Maybe<Table>;
  updateTagCategory?: Maybe<TagCategory>;
  updateUser?: Maybe<User>;
  updateUserPassword?: Maybe<User>;
  updateWaiterCall?: Maybe<WaiterCall>;
  uploadFile?: Maybe<File>;
  upsertLocationSettings?: Maybe<LocationSettings>;
  upsertMenuCardPrintSettings?: Maybe<MenuCardPrintSettings>;
  upsertPaymentSettings?: Maybe<PaymentSettings>;
  upsertPosSystem?: Maybe<PosSystem>;
  upsertTimeControl?: Maybe<TimeControl>;
};


export type MutationCreateAddressArgs = {
  address: AddressCreateInput;
};


export type MutationCreateCompanyArgs = {
  company: CompanyCreateInput;
};


export type MutationCreateItemArgs = {
  item: ItemCreateInput;
  locationSlug: Scalars['String'];
};


export type MutationCreateItemCategoryArgs = {
  itemCategory: ItemCategoryCreateInput;
  locationSlug: Scalars['String'];
};


export type MutationCreateLocationArgs = {
  companySlug: Scalars['String'];
  location: LocationCreateInput;
};


export type MutationCreateMenuArgs = {
  locationSlug: Scalars['String'];
  menu: MenuCreateInput;
};


export type MutationCreateMenuCategoryArgs = {
  locationSlug: Scalars['String'];
  menuCategory: MenuCategoryCreateInput;
};


export type MutationCreateOptionGroupArgs = {
  itemId: Scalars['String'];
  optionGroup: OptionGroupCreateInput;
};


export type MutationCreateOrderArgs = {
  locationSlug: Scalars['String'];
  order: OrderCreateInput;
  tableId: Scalars['String'];
  totalPrice: Scalars['Decimal'];
};


export type MutationCreatePaymentArgs = {
  payment: PaymentCreateInput;
};


export type MutationCreateTableArgs = {
  locationSlug: Scalars['String'];
  table: TableCreateInput;
};


export type MutationCreateTagCategoryArgs = {
  locationSlug: Scalars['String'];
  tagCategory: TagCategoryCreateInput;
};


export type MutationCreateWaiterCallArgs = {
  tableId: Scalars['String'];
  waiterCall: WaiterCallCreateInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCompanyArgs = {
  slug: Scalars['String'];
};


export type MutationDeleteItemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteItemCategoryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteLocationArgs = {
  slug: Scalars['String'];
};


export type MutationDeleteLocationSettingsArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMenuArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMenuCategoryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteOptionGroupArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTableArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTagCategoryArgs = {
  id: Scalars['String'];
};


export type MutationDeleteWaiterCallArgs = {
  id: Scalars['String'];
};


export type MutationLoginUserArgs = {
  user: UserLoginInput;
};


export type MutationResetPasswordArgs = {
  user: UserResetPasswordInput;
};


export type MutationSendOrderToPosSystemArgs = {
  locationSlug: Scalars['String'];
  order?: InputMaybe<OrderSendToPosSystemInput>;
};


export type MutationSyncItemsFromPosSystemArgs = {
  locationSlug: Scalars['String'];
};


export type MutationTranslateMenuCardArgs = {
  locationSlug: Scalars['String'];
};


export type MutationTranslateTextArgs = {
  translation: TranslationInput;
};


export type MutationUpdateAddressArgs = {
  address: AddressUpdateInput;
};


export type MutationUpdateCompanyArgs = {
  company: CompanyUpdateInput;
};


export type MutationUpdateItemArgs = {
  item: ItemUpdateInput;
};


export type MutationUpdateItemBackgroundInformationArgs = {
  item: ItemBackgroundInformationUpdateInput;
};


export type MutationUpdateItemCategoriesInMenuArgs = {
  menu: ItemCategoriesInMenuUpdateInput;
};


export type MutationUpdateItemCategoryArgs = {
  itemCategory: ItemCategoryUpdateInput;
};


export type MutationUpdateItemOptionsArgs = {
  item: ItemOptionsUpdateInput;
};


export type MutationUpdateItemsInItemCategoryArgs = {
  itemCategory: ItemsInItemCategoryUpdateInput;
};


export type MutationUpdateItemsInMenuArgs = {
  menu: ItemsInMenuUpdateInput;
};


export type MutationUpdateLocationArgs = {
  location: LocationUpdateInput;
};


export type MutationUpdateMenuArgs = {
  menu: MenuUpdateInput;
};


export type MutationUpdateMenuCategoriesInLocationArgs = {
  location: MenuCategoriesInLocationUpdateInput;
};


export type MutationUpdateMenuCategoryArgs = {
  menuCategory: MenuCategoryUpdateInput;
};


export type MutationUpdateMenusInMenuCategoryArgs = {
  menuCategory: MenusInMenuCategoryUpdateInput;
};


export type MutationUpdateOptionGroupArgs = {
  itemId: Scalars['String'];
  optionGroup: OptionGroupUpdateInput;
};


export type MutationUpdateOrderArgs = {
  order: OrderUpdateInput;
};


export type MutationUpdateOrderItemArgs = {
  orderItem: OrderItemUpdateInput;
};


export type MutationUpdatePaymentArgs = {
  orderId: Scalars['String'];
  payment: PaymentUpdateInput;
};


export type MutationUpdateTableArgs = {
  table: TableUpdateInput;
};


export type MutationUpdateTagCategoryArgs = {
  tagCategory: TagCategoryUpdateInput;
};


export type MutationUpdateUserArgs = {
  user: UserUpdateInput;
};


export type MutationUpdateUserPasswordArgs = {
  user: UserUpdatePasswordInput;
};


export type MutationUpdateWaiterCallArgs = {
  waiterCall: WaiterCallUpdateInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  path: Scalars['String'];
};


export type MutationUpsertLocationSettingsArgs = {
  location: LocationSettingsUpdateInput;
  locationSlug: Scalars['String'];
};


export type MutationUpsertMenuCardPrintSettingsArgs = {
  locationSlug: Scalars['String'];
  menuCardPrintSettings: MenuCardPrintSettingsUpsertInput;
};


export type MutationUpsertPaymentSettingsArgs = {
  locationSlug: Scalars['String'];
  paymentSettings: PaymentSettingsUpsertInput;
};


export type MutationUpsertPosSystemArgs = {
  locationSlug: Scalars['String'];
  posSystem: PosSystemUpsertInput;
};


export type MutationUpsertTimeControlArgs = {
  itemId: Scalars['String'];
  timeControl: TimeControlUpsertInput;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['String'];
  name: Scalars['JSON'];
  posId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
};

export type OptionGroup = {
  __typename?: 'OptionGroup';
  id: Scalars['String'];
  isAdditive?: Maybe<Scalars['Boolean']>;
  isMultiSelect?: Maybe<Scalars['Boolean']>;
  name: Scalars['JSON'];
  options?: Maybe<Array<Option>>;
  posId?: Maybe<Scalars['Int']>;
};

export type OptionGroupCreateInput = {
  isAdditive?: InputMaybe<Scalars['Boolean']>;
  isMultiSelect?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['JSON'];
  options: Array<OptionUpsertInput>;
};

export type OptionGroupUpdateInput = {
  id: Scalars['String'];
  isAdditive?: InputMaybe<Scalars['Boolean']>;
  isMultiSelect?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['JSON'];
  options: Array<OptionUpsertInput>;
};

export type OptionUpsertInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
  price: Scalars['Decimal'];
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  items?: Maybe<Array<OrderItem>>;
  notes?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Int'];
  payment?: Maybe<Payment>;
  status: OrderStatus;
};

export type OrderCreateInput = {
  items: Array<OrderItemCreateInput>;
  notes?: InputMaybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  category?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSentToPos?: Maybe<Scalars['Boolean']>;
  name: Scalars['JSON'];
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<Array<OrderOption>>;
  posId?: Maybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  quantity: Scalars['Int'];
  refItemId: Scalars['String'];
};

export type OrderItemCreateInput = {
  category?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
  notes?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<OrderOptionInput>>;
  posId?: InputMaybe<Scalars['Int']>;
  price: Scalars['Decimal'];
  quantity: Scalars['Int'];
  refItemId: Scalars['String'];
};

export type OrderItemUpdateInput = {
  id: Scalars['String'];
  isSentToPos?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Decimal']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type OrderOption = {
  __typename?: 'OrderOption';
  group: Scalars['JSON'];
  id: Scalars['String'];
  name: Scalars['JSON'];
  posId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type OrderOptionInput = {
  group: Scalars['JSON'];
  name: Scalars['JSON'];
  posId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Decimal']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type OrderSendToPosSystemInput = {
  isPaid: Scalars['Boolean'];
  items: Array<OrderItemCreateInput>;
  notes?: InputMaybe<Scalars['String']>;
  table: Scalars['Int'];
  tip?: InputMaybe<Scalars['Int']>;
};

export enum OrderStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Ordered = 'ORDERED'
}

export type OrderUpdateInput = {
  id: Scalars['String'];
  status: OrderStatus;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  method: Scalars['String'];
  status: PaymentStatus;
  tip?: Maybe<Scalars['Decimal']>;
};

export type PaymentCreateInput = {
  amount: Scalars['Decimal'];
  method?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  status?: InputMaybe<PaymentStatus>;
};

export enum PaymentMethod {
  Applepay = 'APPLEPAY',
  Googlepay = 'GOOGLEPAY',
  Mastercard = 'MASTERCARD',
  Paypal = 'PAYPAL',
  Twint = 'TWINT',
  Visa = 'VISA'
}

export enum PaymentProvider {
  Saferpay = 'SAFERPAY'
}

export type PaymentSettings = {
  __typename?: 'PaymentSettings';
  customerId: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  methods: Array<PaymentMethod>;
  password: Scalars['String'];
  provider: PaymentProvider;
  terminalId: Scalars['String'];
  url: Scalars['String'];
  userName: Scalars['String'];
};

export type PaymentSettingsUpsertInput = {
  customerId: Scalars['String'];
  isActive: Scalars['Boolean'];
  methods: Array<PaymentMethod>;
  password: Scalars['String'];
  provider: PaymentProvider;
  terminalId: Scalars['String'];
  url: Scalars['String'];
  userName: Scalars['String'];
};

export enum PaymentStatus {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  PayLater = 'PAY_LATER',
  Pending = 'PENDING'
}

export type PaymentUpdateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  method?: InputMaybe<PaymentMethod>;
  status: PaymentStatus;
  tip?: InputMaybe<Scalars['Decimal']>;
};

export type PosSystem = {
  __typename?: 'PosSystem';
  apiKey: Scalars['String'];
  apiKeyOrder: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  lastSync?: Maybe<Scalars['DateTime']>;
  provider: PosSystemProvider;
  url: Scalars['String'];
};

export enum PosSystemProvider {
  Flm = 'FLM'
}

export type PosSystemUpsertInput = {
  apiKey: Scalars['String'];
  apiKeyOrder: Scalars['String'];
  isActive: Scalars['Boolean'];
  provider: PosSystemProvider;
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  companies: Array<Company>;
  company?: Maybe<Company>;
  item?: Maybe<Item>;
  itemCategories: Array<ItemCategory>;
  itemCategory?: Maybe<ItemCategory>;
  items: Array<Item>;
  location?: Maybe<Location>;
  locationPermissions?: Maybe<LocationPermissions>;
  locationSettings?: Maybe<LocationSettings>;
  locations: Array<Location>;
  menu?: Maybe<Menu>;
  menuCardPrintSettings?: Maybe<MenuCardPrintSettings>;
  menuCategories: Array<MenuCategory>;
  menuCategory?: Maybe<MenuCategory>;
  menus: Array<Menu>;
  optionGroup?: Maybe<OptionGroup>;
  optionGroups: Array<OptionGroup>;
  order?: Maybe<Order>;
  paymentSettings?: Maybe<PaymentSettings>;
  posSystem?: Maybe<PosSystem>;
  table?: Maybe<Table>;
  tables?: Maybe<Array<Table>>;
  tablesWithOrders?: Maybe<Array<Table>>;
  tag?: Maybe<Tag>;
  tagCategories: Array<TagCategory>;
  tagCategory?: Maybe<TagCategory>;
  tags: Array<Tag>;
  timeControl?: Maybe<TimeControl>;
  user?: Maybe<User>;
};


export type QueryCompanyArgs = {
  slug: Scalars['String'];
};


export type QueryItemArgs = {
  id: Scalars['String'];
};


export type QueryItemCategoriesArgs = {
  locationSlug: Scalars['String'];
};


export type QueryItemCategoryArgs = {
  id: Scalars['String'];
};


export type QueryItemsArgs = {
  locationSlug: Scalars['String'];
};


export type QueryLocationArgs = {
  filterActive?: InputMaybe<Scalars['Boolean']>;
  slug: Scalars['String'];
};


export type QueryLocationPermissionsArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryLocationSettingsArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryLocationsArgs = {
  companySlug: Scalars['String'];
};


export type QueryMenuArgs = {
  id: Scalars['String'];
};


export type QueryMenuCardPrintSettingsArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryMenuCategoriesArgs = {
  locationSlug: Scalars['String'];
};


export type QueryMenuCategoryArgs = {
  id: Scalars['String'];
};


export type QueryMenusArgs = {
  locationSlug: Scalars['String'];
};


export type QueryOptionGroupArgs = {
  id: Scalars['String'];
};


export type QueryOptionGroupsArgs = {
  itemId: Scalars['String'];
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryPaymentSettingsArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryPosSystemArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryTableArgs = {
  locationSlug: Scalars['String'];
  number: Scalars['Int'];
};


export type QueryTablesArgs = {
  locationSlug: Scalars['String'];
};


export type QueryTablesWithOrdersArgs = {
  locationSlug: Scalars['String'];
};


export type QueryTagArgs = {
  id: Scalars['String'];
};


export type QueryTagCategoriesArgs = {
  locationSlug: Scalars['String'];
};


export type QueryTagCategoryArgs = {
  id: Scalars['String'];
};


export type QueryTagsArgs = {
  locationSlug: Scalars['String'];
};


export type QueryTimeControlArgs = {
  itemId: Scalars['String'];
};


export type QueryUserArgs = {
  authToken?: InputMaybe<Scalars['String']>;
  inviteToken?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderCreated: Scalars['Boolean'];
  waiterCallCreated: Scalars['Boolean'];
};

export enum SupportedLanguages {
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  It = 'IT'
}

export type Table = {
  __typename?: 'Table';
  id: Scalars['String'];
  name?: Maybe<Scalars['JSON']>;
  number: Scalars['Int'];
  orders?: Maybe<Array<Order>>;
  waiterCalls?: Maybe<Array<WaiterCall>>;
};

export type TableCreateInput = {
  name?: InputMaybe<Scalars['JSON']>;
  number: Scalars['Int'];
};

export type TableUpdateInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['JSON']>;
  number: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  name: Scalars['JSON'];
  tagCategory?: Maybe<TagCategory>;
};

export type TagCategory = {
  __typename?: 'TagCategory';
  id: Scalars['String'];
  location?: Maybe<Location>;
  name: Scalars['JSON'];
  tags?: Maybe<Array<Tag>>;
};

export type TagCategoryCreateInput = {
  name: Scalars['JSON'];
  tags: Array<TagUpsertInput>;
};

export type TagCategoryUpdateInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['JSON']>;
  tags: Array<TagUpsertInput>;
};

export type TagUpdateInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['JSON']>;
};

export type TagUpsertInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['JSON'];
};

export type TimeControl = {
  __typename?: 'TimeControl';
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  weekDays?: Maybe<Array<WeekDay>>;
};

export type TimeControlUpsertInput = {
  id?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  weekDays?: InputMaybe<Array<WeekDayUpsertInput>>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  endTime: Scalars['DateTime'];
  id: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type TimeSlotUpsertInput = {
  endTime: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Int'];
};

export type TranslationInput = {
  targetLang: SupportedLanguages;
  text: Array<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  authToken?: Maybe<Scalars['String']>;
  companies?: Maybe<Array<Company>>;
  email: Scalars['String'];
  id: Scalars['String'];
  inviteToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  resetPasswordToken?: Maybe<Scalars['String']>;
  role: UserRole;
};

export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserResetPasswordInput = {
  email: Scalars['String'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Staff = 'STAFF',
  User = 'USER'
}

export type UserUpdateInput = {
  id: Scalars['String'];
  inviteToken: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type UserUpdatePasswordInput = {
  id: Scalars['String'];
  password: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type WaiterCall = {
  __typename?: 'WaiterCall';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<WaiterCallStatus>;
};

export type WaiterCallCreateInput = {
  notes?: InputMaybe<Scalars['String']>;
};

export enum WaiterCallStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type WaiterCallUpdateInput = {
  id: Scalars['String'];
  status: WaiterCallStatus;
};

export type WeekDay = {
  __typename?: 'WeekDay';
  id: Scalars['String'];
  index: Scalars['Int'];
  isActive: Scalars['Boolean'];
  timeSlots?: Maybe<Array<TimeSlot>>;
};

export type WeekDayUpsertInput = {
  id?: InputMaybe<Scalars['String']>;
  index: Scalars['Int'];
  isActive: Scalars['Boolean'];
  timeSlots?: InputMaybe<Array<TimeSlotUpsertInput>>;
};

export type CompanyQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', name: string, slug: string } | null };

export type LocationsQueryVariables = Exact<{
  companySlug: Scalars['String'];
}>;


export type LocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', slug: string, name: any, address?: { __typename?: 'Address', street: string, city: string, zip: number, phone: string, email: string, id: string } | null }> };

export type LocationQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type LocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', slug: string, name: any, id: string, menuCategoriesOrder?: Array<string> | null, address?: { __typename?: 'Address', street: string, city: string, zip: number, phone: string, email: string, id: string } | null, settings?: { __typename?: 'LocationSettings', id: string, languages?: Array<SupportedLanguages> | null, currency?: string | null, logo?: string | null, theme?: any | null, alarmInterval?: number | null, isPopularCategoryActive?: boolean | null } | null, tables?: Array<{ __typename?: 'Table', id: string, number: number, name?: any | null }> | null, menuCategories?: Array<{ __typename?: 'MenuCategory', id: string, name: any, isActive?: boolean | null, menusOrder?: Array<string> | null, menus?: Array<{ __typename?: 'Menu', id: string, name: any, isActive?: boolean | null, itemAndItemCategoriesOrder?: Array<string> | null, itemCategories?: Array<{ __typename?: 'ItemCategory', id: string, name: any, isActive?: boolean | null, itemsOrder?: Array<string> | null, items?: Array<{ __typename?: 'Item', id: string, name: any, price: any, description?: any | null, image?: string | null, isActive?: boolean | null, tags?: Array<{ __typename?: 'Tag', id: string, name: any }> | null, optionGroups?: Array<{ __typename?: 'OptionGroup', id: string, name: any, options?: Array<{ __typename?: 'Option', id: string, name: any, price?: any | null }> | null }> | null }> | null }> | null, items?: Array<{ __typename?: 'Item', id: string, name: any, price: any, description?: any | null, image?: string | null, isActive?: boolean | null, tags?: Array<{ __typename?: 'Tag', id: string, name: any }> | null, optionGroups?: Array<{ __typename?: 'OptionGroup', id: string, name: any, options?: Array<{ __typename?: 'Option', id: string, name: any, price?: any | null }> | null }> | null }> | null }> | null }> | null } | null };

export type LocationSettingsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type LocationSettingsQuery = { __typename?: 'Query', locationSettings?: { __typename?: 'LocationSettings', id: string, languages?: Array<SupportedLanguages> | null, currency?: string | null, logo?: string | null, theme?: any | null, alarmInterval?: number | null, isPopularCategoryActive?: boolean | null } | null };

export type PaymentSettingsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type PaymentSettingsQuery = { __typename?: 'Query', paymentSettings?: { __typename?: 'PaymentSettings', id: string, isActive: boolean, provider: PaymentProvider, url: string, methods: Array<PaymentMethod>, userName: string, password: string, customerId: string, terminalId: string } | null };

export type LocationPermissionsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type LocationPermissionsQuery = { __typename?: 'Query', locationPermissions?: { __typename?: 'LocationPermissions', hasWaiterCall: boolean, hasPayment: boolean, hasPos: boolean, canDirectSendToPos: boolean, cartType: CartType, canAutoTranslate: boolean } | null };

export type PosSystemQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type PosSystemQuery = { __typename?: 'Query', posSystem?: { __typename?: 'PosSystem', id: string, isActive: boolean, provider: PosSystemProvider, url: string, apiKey: string, apiKeyOrder: string, lastSync?: any | null } | null };

export type MenuCardPrintSettingsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type MenuCardPrintSettingsQuery = { __typename?: 'Query', menuCardPrintSettings?: { __typename?: 'MenuCardPrintSettings', id: string, title: string, footer: string, excludeItemIds?: Array<string> | null } | null };

export type CreateLocationMutationVariables = Exact<{
  location: LocationCreateInput;
  companySlug: Scalars['String'];
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', name: any } | null };

export type UpdateLocationMutationVariables = Exact<{
  location: LocationUpdateInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation?: { __typename?: 'Location', name: any } | null };

export type UpdateMenuCategoriesInLocationMutationVariables = Exact<{
  location: MenuCategoriesInLocationUpdateInput;
}>;


export type UpdateMenuCategoriesInLocationMutation = { __typename?: 'Mutation', updateMenuCategoriesInLocation?: { __typename?: 'Location', name: any } | null };

export type DeleteLocationMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', deleteLocation?: { __typename?: 'Location', name: any } | null };

export type UpsertLocationSettingsMutationVariables = Exact<{
  location: LocationSettingsUpdateInput;
  locationSlug: Scalars['String'];
}>;


export type UpsertLocationSettingsMutation = { __typename?: 'Mutation', upsertLocationSettings?: { __typename?: 'LocationSettings', id: string } | null };

export type DeleteLocationSettingsMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteLocationSettingsMutation = { __typename?: 'Mutation', deleteLocationSettings?: { __typename?: 'Location', name: any } | null };

export type UpsertPaymentSettingsMutationVariables = Exact<{
  paymentSettings: PaymentSettingsUpsertInput;
  locationSlug: Scalars['String'];
}>;


export type UpsertPaymentSettingsMutation = { __typename?: 'Mutation', upsertPaymentSettings?: { __typename?: 'PaymentSettings', id: string } | null };

export type UpsertPosSystemMutationVariables = Exact<{
  posSystem: PosSystemUpsertInput;
  locationSlug: Scalars['String'];
}>;


export type UpsertPosSystemMutation = { __typename?: 'Mutation', upsertPosSystem?: { __typename?: 'PosSystem', id: string } | null };

export type SendOrderToPosSystemMutationVariables = Exact<{
  locationSlug: Scalars['String'];
  order: OrderSendToPosSystemInput;
}>;


export type SendOrderToPosSystemMutation = { __typename?: 'Mutation', sendOrderToPosSystem?: { __typename?: 'Order', id: string } | null };

export type SyncItemsFromPosSystemMutationVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type SyncItemsFromPosSystemMutation = { __typename?: 'Mutation', syncItemsFromPosSystem?: { __typename?: 'ItemSynchResponse', createdItemsCount: number, createdMenusCount: number, updatedItemsCount: number, updatedMenusCount: number } | null };

export type UpsertMenuCardPrintSettingsMutationVariables = Exact<{
  menuCardPrintSettings: MenuCardPrintSettingsUpsertInput;
  locationSlug: Scalars['String'];
}>;


export type UpsertMenuCardPrintSettingsMutation = { __typename?: 'Mutation', upsertMenuCardPrintSettings?: { __typename?: 'MenuCardPrintSettings', id: string } | null };

export type TablesQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type TablesQuery = { __typename?: 'Query', tables?: Array<{ __typename?: 'Table', id: string, number: number, name?: any | null }> | null };

export type TableQueryVariables = Exact<{
  number: Scalars['Int'];
  locationSlug: Scalars['String'];
}>;


export type TableQuery = { __typename?: 'Query', table?: { __typename?: 'Table', id: string, number: number, name?: any | null } | null };

export type CreateTableMutationVariables = Exact<{
  table: TableCreateInput;
  locationSlug: Scalars['String'];
}>;


export type CreateTableMutation = { __typename?: 'Mutation', createTable?: { __typename?: 'Table', number: number } | null };

export type UpdateTableMutationVariables = Exact<{
  table: TableUpdateInput;
}>;


export type UpdateTableMutation = { __typename?: 'Mutation', updateTable?: { __typename?: 'Table', number: number } | null };

export type DeleteTableMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTableMutation = { __typename?: 'Mutation', deleteTable?: { __typename?: 'Table', number: number } | null };

export type MenuCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MenuCategoryQuery = { __typename?: 'Query', menuCategory?: { __typename?: 'MenuCategory', id: string, name: any, isActive?: boolean | null, menusOrder?: Array<string> | null, menus?: Array<{ __typename?: 'Menu', id: string, name: any }> | null } | null };

export type MenuCategoriesQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type MenuCategoriesQuery = { __typename?: 'Query', menuCategories: Array<{ __typename?: 'MenuCategory', id: string, name: any, isActive?: boolean | null, menus?: Array<{ __typename?: 'Menu', id: string, name: any }> | null, location?: { __typename?: 'Location', id: string, name: any, slug: string } | null }> };

export type CreateMenuCategoryMutationVariables = Exact<{
  menuCategory: MenuCategoryCreateInput;
  locationSlug: Scalars['String'];
}>;


export type CreateMenuCategoryMutation = { __typename?: 'Mutation', createMenuCategory?: { __typename?: 'MenuCategory', name: any } | null };

export type UpdateMenuCategoryMutationVariables = Exact<{
  menuCategory: MenuCategoryUpdateInput;
}>;


export type UpdateMenuCategoryMutation = { __typename?: 'Mutation', updateMenuCategory?: { __typename?: 'MenuCategory', name: any } | null };

export type UpdateMenusInMenuCategoryMutationVariables = Exact<{
  menuCategory: MenusInMenuCategoryUpdateInput;
}>;


export type UpdateMenusInMenuCategoryMutation = { __typename?: 'Mutation', updateMenusInMenuCategory?: { __typename?: 'MenuCategory', name: any } | null };

export type DeleteMenuCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMenuCategoryMutation = { __typename?: 'Mutation', deleteMenuCategory?: { __typename?: 'MenuCategory', name: any } | null };

export type MenuQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MenuQuery = { __typename?: 'Query', menu?: { __typename?: 'Menu', id: string, name: any, isActive?: boolean | null, itemAndItemCategoriesOrder?: Array<string> | null, itemsOrder?: Array<string> | null, itemCategories?: Array<{ __typename?: 'ItemCategory', id: string, name: any }> | null, items?: Array<{ __typename?: 'Item', id: string, name: any, price: any }> | null, menuCategory?: { __typename?: 'MenuCategory', id: string, name: any } | null } | null };

export type MenusQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type MenusQuery = { __typename?: 'Query', menus: Array<{ __typename?: 'Menu', id: string, name: any, menuCategoryId?: string | null, isActive?: boolean | null, itemCategories?: Array<{ __typename?: 'ItemCategory', id: string, name: any }> | null, items?: Array<{ __typename?: 'Item', id: string, name: any, price: any }> | null, menuCategory?: { __typename?: 'MenuCategory', id: string, name: any } | null }> };

export type CreateMenuMutationVariables = Exact<{
  menu: MenuCreateInput;
  locationSlug: Scalars['String'];
}>;


export type CreateMenuMutation = { __typename?: 'Mutation', createMenu?: { __typename?: 'Menu', name: any } | null };

export type UpdateMenuMutationVariables = Exact<{
  menu: MenuUpdateInput;
}>;


export type UpdateMenuMutation = { __typename?: 'Mutation', updateMenu?: { __typename?: 'Menu', name: any } | null };

export type UpdateItemCategoriesInMenuMutationVariables = Exact<{
  menu: ItemCategoriesInMenuUpdateInput;
}>;


export type UpdateItemCategoriesInMenuMutation = { __typename?: 'Mutation', updateItemCategoriesInMenu?: { __typename?: 'Menu', name: any } | null };

export type UpdateItemsInMenuMutationVariables = Exact<{
  menu: ItemsInMenuUpdateInput;
}>;


export type UpdateItemsInMenuMutation = { __typename?: 'Mutation', updateItemsInMenu?: { __typename?: 'Menu', name: any } | null };

export type DeleteMenuMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMenuMutation = { __typename?: 'Mutation', deleteMenu?: { __typename?: 'Menu', name: any } | null };

export type ItemCategoriesQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type ItemCategoriesQuery = { __typename?: 'Query', itemCategories: Array<{ __typename?: 'ItemCategory', id: string, name: any, isActive?: boolean | null, items?: Array<{ __typename?: 'Item', id: string, name: any, price: any, image?: string | null }> | null, menu?: { __typename?: 'Menu', id: string, name: any } | null }> };

export type ItemCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ItemCategoryQuery = { __typename?: 'Query', itemCategory?: { __typename?: 'ItemCategory', id: string, name: any, isActive?: boolean | null, itemsOrder?: Array<string> | null, items?: Array<{ __typename?: 'Item', id: string, name: any, price: any, image?: string | null }> | null, menu?: { __typename?: 'Menu', id: string, name: any } | null } | null };

export type CreateItemCategoryMutationVariables = Exact<{
  itemCategory: ItemCategoryCreateInput;
  locationSlug: Scalars['String'];
}>;


export type CreateItemCategoryMutation = { __typename?: 'Mutation', createItemCategory?: { __typename?: 'ItemCategory', name: any } | null };

export type UpdateItemCategoryMutationVariables = Exact<{
  itemCategory: ItemCategoryUpdateInput;
}>;


export type UpdateItemCategoryMutation = { __typename?: 'Mutation', updateItemCategory?: { __typename?: 'ItemCategory', name: any } | null };

export type UpdateItemsInItemCategoryMutationVariables = Exact<{
  itemCategory: ItemsInItemCategoryUpdateInput;
}>;


export type UpdateItemsInItemCategoryMutation = { __typename?: 'Mutation', updateItemsInItemCategory?: { __typename?: 'ItemCategory', name: any } | null };

export type DeleteItemCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteItemCategoryMutation = { __typename?: 'Mutation', deleteItemCategory?: { __typename?: 'ItemCategory', name: any } | null };

export type ItemsQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type ItemsQuery = { __typename?: 'Query', items: Array<{ __typename?: 'Item', id: string, name: any, price: any, description?: any | null, backgroundInformation?: any | null, image?: string | null, isActive?: boolean | null, itemCategory?: { __typename?: 'ItemCategory', id: string, name: any } | null, menu?: { __typename?: 'Menu', id: string, name: any } | null, tags?: Array<{ __typename?: 'Tag', id: string, name: any }> | null }> };

export type ItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ItemQuery = { __typename?: 'Query', item?: { __typename?: 'Item', id: string, name: any, price: any, description?: any | null, backgroundInformation?: any | null, image?: string | null, isActive?: boolean | null, optionGroupsOrder?: Array<string> | null, tags?: Array<{ __typename?: 'Tag', id: string, name: any }> | null, itemCategory?: { __typename?: 'ItemCategory', id: string, name: any } | null, menu?: { __typename?: 'Menu', id: string, name: any } | null } | null };

export type CreateItemMutationVariables = Exact<{
  item: ItemCreateInput;
  locationSlug: Scalars['String'];
}>;


export type CreateItemMutation = { __typename?: 'Mutation', createItem?: { __typename?: 'Item', name: any } | null };

export type UpdateItemMutationVariables = Exact<{
  item: ItemUpdateInput;
}>;


export type UpdateItemMutation = { __typename?: 'Mutation', updateItem?: { __typename?: 'Item', name: any } | null };

export type UpdateItemOptionsMutationVariables = Exact<{
  item: ItemOptionsUpdateInput;
}>;


export type UpdateItemOptionsMutation = { __typename?: 'Mutation', updateItemOptions?: { __typename?: 'Item', name: any } | null };

export type UpdateItemBackgroundInformationMutationVariables = Exact<{
  item: ItemBackgroundInformationUpdateInput;
}>;


export type UpdateItemBackgroundInformationMutation = { __typename?: 'Mutation', updateItemBackgroundInformation?: { __typename?: 'Item', name: any } | null };

export type DeleteItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteItemMutation = { __typename?: 'Mutation', deleteItem?: { __typename?: 'Item', name: any } | null };

export type OptionGroupsQueryVariables = Exact<{
  itemId: Scalars['String'];
}>;


export type OptionGroupsQuery = { __typename?: 'Query', optionGroups: Array<{ __typename?: 'OptionGroup', id: string, name: any, isMultiSelect?: boolean | null, isAdditive?: boolean | null, options?: Array<{ __typename?: 'Option', id: string, name: any, price?: any | null }> | null }> };

export type OptionGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OptionGroupQuery = { __typename?: 'Query', optionGroup?: { __typename?: 'OptionGroup', id: string, name: any, isMultiSelect?: boolean | null, isAdditive?: boolean | null, options?: Array<{ __typename?: 'Option', id: string, name: any, price?: any | null }> | null } | null };

export type CreateOptionGroupMutationVariables = Exact<{
  optionGroup: OptionGroupCreateInput;
  itemId: Scalars['String'];
}>;


export type CreateOptionGroupMutation = { __typename?: 'Mutation', createOptionGroup?: { __typename?: 'OptionGroup', name: any } | null };

export type UpdateOptionGroupMutationVariables = Exact<{
  optionGroup: OptionGroupUpdateInput;
  itemId: Scalars['String'];
}>;


export type UpdateOptionGroupMutation = { __typename?: 'Mutation', updateOptionGroup?: { __typename?: 'OptionGroup', name: any } | null };

export type DeleteOptionGroupMutationVariables = Exact<{
  id: Scalars['String'];
  itemId: Scalars['String'];
}>;


export type DeleteOptionGroupMutation = { __typename?: 'Mutation', deleteOptionGroup?: { __typename?: 'OptionGroup', name: any } | null };

export type TimeControlQueryVariables = Exact<{
  itemId: Scalars['String'];
}>;


export type TimeControlQuery = { __typename?: 'Query', timeControl?: { __typename?: 'TimeControl', id: string, isActive: boolean, weekDays?: Array<{ __typename?: 'WeekDay', id: string, isActive: boolean, index: number, timeSlots?: Array<{ __typename?: 'TimeSlot', id: string, startTime: any, endTime: any }> | null }> | null } | null };

export type UpsertTimeControlMutationVariables = Exact<{
  timeControl: TimeControlUpsertInput;
  itemId: Scalars['String'];
}>;


export type UpsertTimeControlMutation = { __typename?: 'Mutation', upsertTimeControl?: { __typename?: 'TimeControl', id: string, isActive: boolean } | null };

export type TagCategoriesQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type TagCategoriesQuery = { __typename?: 'Query', tagCategories: Array<{ __typename?: 'TagCategory', id: string, name: any, location?: { __typename?: 'Location', id: string, name: any, slug: string } | null, tags?: Array<{ __typename?: 'Tag', id: string, name: any }> | null }> };

export type TagCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TagCategoryQuery = { __typename?: 'Query', tagCategory?: { __typename?: 'TagCategory', id: string, name: any, location?: { __typename?: 'Location', id: string, name: any, slug: string } | null, tags?: Array<{ __typename?: 'Tag', id: string, name: any }> | null } | null };

export type CreateTagCategoryMutationVariables = Exact<{
  tagCategory: TagCategoryCreateInput;
  locationSlug: Scalars['String'];
}>;


export type CreateTagCategoryMutation = { __typename?: 'Mutation', createTagCategory?: { __typename?: 'TagCategory', name: any } | null };

export type UpdateTagCategoryMutationVariables = Exact<{
  tagCategory: TagCategoryUpdateInput;
}>;


export type UpdateTagCategoryMutation = { __typename?: 'Mutation', updateTagCategory?: { __typename?: 'TagCategory', name: any } | null };

export type DeleteTagCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTagCategoryMutation = { __typename?: 'Mutation', deleteTagCategory?: { __typename?: 'TagCategory', name: any } | null };

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  path: Scalars['String'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'File', fileUrl: string } | null };

export type TablesWithOrdersQueryVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type TablesWithOrdersQuery = { __typename?: 'Query', tablesWithOrders?: Array<{ __typename?: 'Table', id: string, number: number, name?: any | null, orders?: Array<{ __typename?: 'Order', id: string, orderNumber: number, notes?: string | null, status: OrderStatus, createdAt: any, items?: Array<{ __typename?: 'OrderItem', id: string, name: any, category?: string | null, price: any, quantity: number, notes?: string | null, refItemId: string, posId?: number | null, isSentToPos?: boolean | null, options?: Array<{ __typename?: 'OrderOption', id: string, name: any, group: any, price?: any | null, posId?: number | null, quantity?: number | null }> | null }> | null, payment?: { __typename?: 'Payment', id: string, amount: any, createdAt: any, method: string, status: PaymentStatus, tip?: any | null } | null }> | null, waiterCalls?: Array<{ __typename?: 'WaiterCall', id: string, createdAt: any, notes?: string | null, status?: WaiterCallStatus | null }> | null }> | null };

export type UpdateOrderMutationVariables = Exact<{
  order: OrderUpdateInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder?: { __typename?: 'Order', id: string, status: OrderStatus } | null };

export type UpdateOrderItemMutationVariables = Exact<{
  orderItem: OrderItemUpdateInput;
}>;


export type UpdateOrderItemMutation = { __typename?: 'Mutation', updateOrderItem?: { __typename?: 'OrderItem', id: string } | null };

export type UpdateWaiterCallMutationVariables = Exact<{
  waiterCall: WaiterCallUpdateInput;
}>;


export type UpdateWaiterCallMutation = { __typename?: 'Mutation', updateWaiterCall?: { __typename?: 'WaiterCall', id: string, status?: WaiterCallStatus | null } | null };

export type OnOrderCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnOrderCreatedSubscription = { __typename?: 'Subscription', orderCreated: boolean };

export type OnWaiterCallCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnWaiterCallCreatedSubscription = { __typename?: 'Subscription', waiterCallCreated: boolean };

export type TranslateTextMutationVariables = Exact<{
  translation: TranslationInput;
}>;


export type TranslateTextMutation = { __typename?: 'Mutation', translateText?: Array<string | null> | null };

export type TranslateMenuCardMutationVariables = Exact<{
  locationSlug: Scalars['String'];
}>;


export type TranslateMenuCardMutation = { __typename?: 'Mutation', translateMenuCard?: string | null };

export type UserQueryVariables = Exact<{
  inviteToken?: InputMaybe<Scalars['String']>;
  authToken?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, name?: string | null, role: UserRole } | null };

export type UpdateUserMutationVariables = Exact<{
  user: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, email: string } | null };

export type LoginUserMutationVariables = Exact<{
  user: UserLoginInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'User', name?: string | null, email: string, role: UserRole, authToken?: string | null, companies?: Array<{ __typename?: 'Company', slug: string }> | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  user: UserResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: string | null };

export type UpdateUserPasswordMutationVariables = Exact<{
  user: UserUpdatePasswordInput;
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword?: { __typename?: 'User', name?: string | null, email: string } | null };


export const CompanyDocument = gql`
    query Company($slug: String!) {
  company(slug: $slug) {
    name
    slug
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const LocationsDocument = gql`
    query Locations($companySlug: String!) {
  locations(companySlug: $companySlug) {
    slug
    name
    address {
      street
      city
      zip
      phone
      email
      id
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const LocationDocument = gql`
    query Location($slug: String!) {
  location(slug: $slug) {
    slug
    name
    id
    address {
      street
      city
      zip
      phone
      email
      id
    }
    settings {
      id
      languages
      currency
      logo
      theme
      alarmInterval
      isPopularCategoryActive
    }
    tables {
      id
      number
      name
    }
    menuCategoriesOrder
    menuCategories {
      id
      name
      isActive
      menusOrder
      menus {
        id
        name
        isActive
        itemAndItemCategoriesOrder
        itemCategories {
          id
          name
          isActive
          itemsOrder
          items {
            id
            name
            price
            description
            image
            isActive
            tags {
              id
              name
            }
            optionGroups {
              id
              name
              options {
                id
                name
                price
              }
            }
          }
        }
        items {
          id
          name
          price
          description
          image
          isActive
          tags {
            id
            name
          }
          optionGroups {
            id
            name
            options {
              id
              name
              price
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
      }
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
        }
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>;
export const LocationSettingsDocument = gql`
    query LocationSettings($locationSlug: String!) {
  locationSettings(locationSlug: $locationSlug) {
    id
    languages
    currency
    logo
    theme
    alarmInterval
    isPopularCategoryActive
  }
}
    `;

/**
 * __useLocationSettingsQuery__
 *
 * To run a query within a React component, call `useLocationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSettingsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useLocationSettingsQuery(baseOptions: Apollo.QueryHookOptions<LocationSettingsQuery, LocationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationSettingsQuery, LocationSettingsQueryVariables>(LocationSettingsDocument, options);
      }
export function useLocationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationSettingsQuery, LocationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationSettingsQuery, LocationSettingsQueryVariables>(LocationSettingsDocument, options);
        }
export type LocationSettingsQueryHookResult = ReturnType<typeof useLocationSettingsQuery>;
export type LocationSettingsLazyQueryHookResult = ReturnType<typeof useLocationSettingsLazyQuery>;
export type LocationSettingsQueryResult = Apollo.QueryResult<LocationSettingsQuery, LocationSettingsQueryVariables>;
export const PaymentSettingsDocument = gql`
    query PaymentSettings($locationSlug: String!) {
  paymentSettings(locationSlug: $locationSlug) {
    id
    isActive
    provider
    url
    methods
    userName
    password
    customerId
    terminalId
  }
}
    `;

/**
 * __usePaymentSettingsQuery__
 *
 * To run a query within a React component, call `usePaymentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSettingsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function usePaymentSettingsQuery(baseOptions: Apollo.QueryHookOptions<PaymentSettingsQuery, PaymentSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentSettingsQuery, PaymentSettingsQueryVariables>(PaymentSettingsDocument, options);
      }
export function usePaymentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentSettingsQuery, PaymentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentSettingsQuery, PaymentSettingsQueryVariables>(PaymentSettingsDocument, options);
        }
export type PaymentSettingsQueryHookResult = ReturnType<typeof usePaymentSettingsQuery>;
export type PaymentSettingsLazyQueryHookResult = ReturnType<typeof usePaymentSettingsLazyQuery>;
export type PaymentSettingsQueryResult = Apollo.QueryResult<PaymentSettingsQuery, PaymentSettingsQueryVariables>;
export const LocationPermissionsDocument = gql`
    query LocationPermissions($locationSlug: String!) {
  locationPermissions(locationSlug: $locationSlug) {
    hasWaiterCall
    hasPayment
    hasPos
    canDirectSendToPos
    cartType
    canAutoTranslate
  }
}
    `;

/**
 * __useLocationPermissionsQuery__
 *
 * To run a query within a React component, call `useLocationPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPermissionsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useLocationPermissionsQuery(baseOptions: Apollo.QueryHookOptions<LocationPermissionsQuery, LocationPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationPermissionsQuery, LocationPermissionsQueryVariables>(LocationPermissionsDocument, options);
      }
export function useLocationPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationPermissionsQuery, LocationPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationPermissionsQuery, LocationPermissionsQueryVariables>(LocationPermissionsDocument, options);
        }
export type LocationPermissionsQueryHookResult = ReturnType<typeof useLocationPermissionsQuery>;
export type LocationPermissionsLazyQueryHookResult = ReturnType<typeof useLocationPermissionsLazyQuery>;
export type LocationPermissionsQueryResult = Apollo.QueryResult<LocationPermissionsQuery, LocationPermissionsQueryVariables>;
export const PosSystemDocument = gql`
    query PosSystem($locationSlug: String!) {
  posSystem(locationSlug: $locationSlug) {
    id
    isActive
    provider
    url
    apiKey
    apiKeyOrder
    lastSync
  }
}
    `;

/**
 * __usePosSystemQuery__
 *
 * To run a query within a React component, call `usePosSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePosSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePosSystemQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function usePosSystemQuery(baseOptions: Apollo.QueryHookOptions<PosSystemQuery, PosSystemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PosSystemQuery, PosSystemQueryVariables>(PosSystemDocument, options);
      }
export function usePosSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosSystemQuery, PosSystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PosSystemQuery, PosSystemQueryVariables>(PosSystemDocument, options);
        }
export type PosSystemQueryHookResult = ReturnType<typeof usePosSystemQuery>;
export type PosSystemLazyQueryHookResult = ReturnType<typeof usePosSystemLazyQuery>;
export type PosSystemQueryResult = Apollo.QueryResult<PosSystemQuery, PosSystemQueryVariables>;
export const MenuCardPrintSettingsDocument = gql`
    query MenuCardPrintSettings($locationSlug: String!) {
  menuCardPrintSettings(locationSlug: $locationSlug) {
    id
    title
    footer
    excludeItemIds
  }
}
    `;

/**
 * __useMenuCardPrintSettingsQuery__
 *
 * To run a query within a React component, call `useMenuCardPrintSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuCardPrintSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuCardPrintSettingsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useMenuCardPrintSettingsQuery(baseOptions: Apollo.QueryHookOptions<MenuCardPrintSettingsQuery, MenuCardPrintSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuCardPrintSettingsQuery, MenuCardPrintSettingsQueryVariables>(MenuCardPrintSettingsDocument, options);
      }
export function useMenuCardPrintSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuCardPrintSettingsQuery, MenuCardPrintSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuCardPrintSettingsQuery, MenuCardPrintSettingsQueryVariables>(MenuCardPrintSettingsDocument, options);
        }
export type MenuCardPrintSettingsQueryHookResult = ReturnType<typeof useMenuCardPrintSettingsQuery>;
export type MenuCardPrintSettingsLazyQueryHookResult = ReturnType<typeof useMenuCardPrintSettingsLazyQuery>;
export type MenuCardPrintSettingsQueryResult = Apollo.QueryResult<MenuCardPrintSettingsQuery, MenuCardPrintSettingsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($location: LocationCreateInput!, $companySlug: String!) {
  createLocation(location: $location, companySlug: $companySlug) {
    name
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($location: LocationUpdateInput!) {
  updateLocation(location: $location) {
    name
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateMenuCategoriesInLocationDocument = gql`
    mutation UpdateMenuCategoriesInLocation($location: MenuCategoriesInLocationUpdateInput!) {
  updateMenuCategoriesInLocation(location: $location) {
    name
  }
}
    `;
export type UpdateMenuCategoriesInLocationMutationFn = Apollo.MutationFunction<UpdateMenuCategoriesInLocationMutation, UpdateMenuCategoriesInLocationMutationVariables>;

/**
 * __useUpdateMenuCategoriesInLocationMutation__
 *
 * To run a mutation, you first call `useUpdateMenuCategoriesInLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuCategoriesInLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuCategoriesInLocationMutation, { data, loading, error }] = useUpdateMenuCategoriesInLocationMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateMenuCategoriesInLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuCategoriesInLocationMutation, UpdateMenuCategoriesInLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuCategoriesInLocationMutation, UpdateMenuCategoriesInLocationMutationVariables>(UpdateMenuCategoriesInLocationDocument, options);
      }
export type UpdateMenuCategoriesInLocationMutationHookResult = ReturnType<typeof useUpdateMenuCategoriesInLocationMutation>;
export type UpdateMenuCategoriesInLocationMutationResult = Apollo.MutationResult<UpdateMenuCategoriesInLocationMutation>;
export type UpdateMenuCategoriesInLocationMutationOptions = Apollo.BaseMutationOptions<UpdateMenuCategoriesInLocationMutation, UpdateMenuCategoriesInLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($slug: String!) {
  deleteLocation(slug: $slug) {
    name
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const UpsertLocationSettingsDocument = gql`
    mutation UpsertLocationSettings($location: LocationSettingsUpdateInput!, $locationSlug: String!) {
  upsertLocationSettings(location: $location, locationSlug: $locationSlug) {
    id
  }
}
    `;
export type UpsertLocationSettingsMutationFn = Apollo.MutationFunction<UpsertLocationSettingsMutation, UpsertLocationSettingsMutationVariables>;

/**
 * __useUpsertLocationSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertLocationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationSettingsMutation, { data, loading, error }] = useUpsertLocationSettingsMutation({
 *   variables: {
 *      location: // value for 'location'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useUpsertLocationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLocationSettingsMutation, UpsertLocationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLocationSettingsMutation, UpsertLocationSettingsMutationVariables>(UpsertLocationSettingsDocument, options);
      }
export type UpsertLocationSettingsMutationHookResult = ReturnType<typeof useUpsertLocationSettingsMutation>;
export type UpsertLocationSettingsMutationResult = Apollo.MutationResult<UpsertLocationSettingsMutation>;
export type UpsertLocationSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertLocationSettingsMutation, UpsertLocationSettingsMutationVariables>;
export const DeleteLocationSettingsDocument = gql`
    mutation DeleteLocationSettings($id: String!) {
  deleteLocationSettings(id: $id) {
    name
  }
}
    `;
export type DeleteLocationSettingsMutationFn = Apollo.MutationFunction<DeleteLocationSettingsMutation, DeleteLocationSettingsMutationVariables>;

/**
 * __useDeleteLocationSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteLocationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationSettingsMutation, { data, loading, error }] = useDeleteLocationSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationSettingsMutation, DeleteLocationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationSettingsMutation, DeleteLocationSettingsMutationVariables>(DeleteLocationSettingsDocument, options);
      }
export type DeleteLocationSettingsMutationHookResult = ReturnType<typeof useDeleteLocationSettingsMutation>;
export type DeleteLocationSettingsMutationResult = Apollo.MutationResult<DeleteLocationSettingsMutation>;
export type DeleteLocationSettingsMutationOptions = Apollo.BaseMutationOptions<DeleteLocationSettingsMutation, DeleteLocationSettingsMutationVariables>;
export const UpsertPaymentSettingsDocument = gql`
    mutation UpsertPaymentSettings($paymentSettings: PaymentSettingsUpsertInput!, $locationSlug: String!) {
  upsertPaymentSettings(
    paymentSettings: $paymentSettings
    locationSlug: $locationSlug
  ) {
    id
  }
}
    `;
export type UpsertPaymentSettingsMutationFn = Apollo.MutationFunction<UpsertPaymentSettingsMutation, UpsertPaymentSettingsMutationVariables>;

/**
 * __useUpsertPaymentSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertPaymentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPaymentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPaymentSettingsMutation, { data, loading, error }] = useUpsertPaymentSettingsMutation({
 *   variables: {
 *      paymentSettings: // value for 'paymentSettings'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useUpsertPaymentSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPaymentSettingsMutation, UpsertPaymentSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPaymentSettingsMutation, UpsertPaymentSettingsMutationVariables>(UpsertPaymentSettingsDocument, options);
      }
export type UpsertPaymentSettingsMutationHookResult = ReturnType<typeof useUpsertPaymentSettingsMutation>;
export type UpsertPaymentSettingsMutationResult = Apollo.MutationResult<UpsertPaymentSettingsMutation>;
export type UpsertPaymentSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertPaymentSettingsMutation, UpsertPaymentSettingsMutationVariables>;
export const UpsertPosSystemDocument = gql`
    mutation UpsertPosSystem($posSystem: PosSystemUpsertInput!, $locationSlug: String!) {
  upsertPosSystem(posSystem: $posSystem, locationSlug: $locationSlug) {
    id
  }
}
    `;
export type UpsertPosSystemMutationFn = Apollo.MutationFunction<UpsertPosSystemMutation, UpsertPosSystemMutationVariables>;

/**
 * __useUpsertPosSystemMutation__
 *
 * To run a mutation, you first call `useUpsertPosSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPosSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPosSystemMutation, { data, loading, error }] = useUpsertPosSystemMutation({
 *   variables: {
 *      posSystem: // value for 'posSystem'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useUpsertPosSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPosSystemMutation, UpsertPosSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPosSystemMutation, UpsertPosSystemMutationVariables>(UpsertPosSystemDocument, options);
      }
export type UpsertPosSystemMutationHookResult = ReturnType<typeof useUpsertPosSystemMutation>;
export type UpsertPosSystemMutationResult = Apollo.MutationResult<UpsertPosSystemMutation>;
export type UpsertPosSystemMutationOptions = Apollo.BaseMutationOptions<UpsertPosSystemMutation, UpsertPosSystemMutationVariables>;
export const SendOrderToPosSystemDocument = gql`
    mutation SendOrderToPosSystem($locationSlug: String!, $order: OrderSendToPosSystemInput!) {
  sendOrderToPosSystem(locationSlug: $locationSlug, order: $order) {
    id
  }
}
    `;
export type SendOrderToPosSystemMutationFn = Apollo.MutationFunction<SendOrderToPosSystemMutation, SendOrderToPosSystemMutationVariables>;

/**
 * __useSendOrderToPosSystemMutation__
 *
 * To run a mutation, you first call `useSendOrderToPosSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderToPosSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderToPosSystemMutation, { data, loading, error }] = useSendOrderToPosSystemMutation({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSendOrderToPosSystemMutation(baseOptions?: Apollo.MutationHookOptions<SendOrderToPosSystemMutation, SendOrderToPosSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOrderToPosSystemMutation, SendOrderToPosSystemMutationVariables>(SendOrderToPosSystemDocument, options);
      }
export type SendOrderToPosSystemMutationHookResult = ReturnType<typeof useSendOrderToPosSystemMutation>;
export type SendOrderToPosSystemMutationResult = Apollo.MutationResult<SendOrderToPosSystemMutation>;
export type SendOrderToPosSystemMutationOptions = Apollo.BaseMutationOptions<SendOrderToPosSystemMutation, SendOrderToPosSystemMutationVariables>;
export const SyncItemsFromPosSystemDocument = gql`
    mutation SyncItemsFromPosSystem($locationSlug: String!) {
  syncItemsFromPosSystem(locationSlug: $locationSlug) {
    createdItemsCount
    createdMenusCount
    updatedItemsCount
    updatedMenusCount
  }
}
    `;
export type SyncItemsFromPosSystemMutationFn = Apollo.MutationFunction<SyncItemsFromPosSystemMutation, SyncItemsFromPosSystemMutationVariables>;

/**
 * __useSyncItemsFromPosSystemMutation__
 *
 * To run a mutation, you first call `useSyncItemsFromPosSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncItemsFromPosSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncItemsFromPosSystemMutation, { data, loading, error }] = useSyncItemsFromPosSystemMutation({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useSyncItemsFromPosSystemMutation(baseOptions?: Apollo.MutationHookOptions<SyncItemsFromPosSystemMutation, SyncItemsFromPosSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncItemsFromPosSystemMutation, SyncItemsFromPosSystemMutationVariables>(SyncItemsFromPosSystemDocument, options);
      }
export type SyncItemsFromPosSystemMutationHookResult = ReturnType<typeof useSyncItemsFromPosSystemMutation>;
export type SyncItemsFromPosSystemMutationResult = Apollo.MutationResult<SyncItemsFromPosSystemMutation>;
export type SyncItemsFromPosSystemMutationOptions = Apollo.BaseMutationOptions<SyncItemsFromPosSystemMutation, SyncItemsFromPosSystemMutationVariables>;
export const UpsertMenuCardPrintSettingsDocument = gql`
    mutation UpsertMenuCardPrintSettings($menuCardPrintSettings: MenuCardPrintSettingsUpsertInput!, $locationSlug: String!) {
  upsertMenuCardPrintSettings(
    menuCardPrintSettings: $menuCardPrintSettings
    locationSlug: $locationSlug
  ) {
    id
  }
}
    `;
export type UpsertMenuCardPrintSettingsMutationFn = Apollo.MutationFunction<UpsertMenuCardPrintSettingsMutation, UpsertMenuCardPrintSettingsMutationVariables>;

/**
 * __useUpsertMenuCardPrintSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertMenuCardPrintSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMenuCardPrintSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMenuCardPrintSettingsMutation, { data, loading, error }] = useUpsertMenuCardPrintSettingsMutation({
 *   variables: {
 *      menuCardPrintSettings: // value for 'menuCardPrintSettings'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useUpsertMenuCardPrintSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMenuCardPrintSettingsMutation, UpsertMenuCardPrintSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMenuCardPrintSettingsMutation, UpsertMenuCardPrintSettingsMutationVariables>(UpsertMenuCardPrintSettingsDocument, options);
      }
export type UpsertMenuCardPrintSettingsMutationHookResult = ReturnType<typeof useUpsertMenuCardPrintSettingsMutation>;
export type UpsertMenuCardPrintSettingsMutationResult = Apollo.MutationResult<UpsertMenuCardPrintSettingsMutation>;
export type UpsertMenuCardPrintSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertMenuCardPrintSettingsMutation, UpsertMenuCardPrintSettingsMutationVariables>;
export const TablesDocument = gql`
    query Tables($locationSlug: String!) {
  tables(locationSlug: $locationSlug) {
    id
    number
    name
  }
}
    `;

/**
 * __useTablesQuery__
 *
 * To run a query within a React component, call `useTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTablesQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useTablesQuery(baseOptions: Apollo.QueryHookOptions<TablesQuery, TablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TablesQuery, TablesQueryVariables>(TablesDocument, options);
      }
export function useTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TablesQuery, TablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TablesQuery, TablesQueryVariables>(TablesDocument, options);
        }
export type TablesQueryHookResult = ReturnType<typeof useTablesQuery>;
export type TablesLazyQueryHookResult = ReturnType<typeof useTablesLazyQuery>;
export type TablesQueryResult = Apollo.QueryResult<TablesQuery, TablesQueryVariables>;
export const TableDocument = gql`
    query Table($number: Int!, $locationSlug: String!) {
  table(number: $number, locationSlug: $locationSlug) {
    id
    number
    name
  }
}
    `;

/**
 * __useTableQuery__
 *
 * To run a query within a React component, call `useTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableQuery({
 *   variables: {
 *      number: // value for 'number'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useTableQuery(baseOptions: Apollo.QueryHookOptions<TableQuery, TableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableQuery, TableQueryVariables>(TableDocument, options);
      }
export function useTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableQuery, TableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableQuery, TableQueryVariables>(TableDocument, options);
        }
export type TableQueryHookResult = ReturnType<typeof useTableQuery>;
export type TableLazyQueryHookResult = ReturnType<typeof useTableLazyQuery>;
export type TableQueryResult = Apollo.QueryResult<TableQuery, TableQueryVariables>;
export const CreateTableDocument = gql`
    mutation CreateTable($table: TableCreateInput!, $locationSlug: String!) {
  createTable(table: $table, locationSlug: $locationSlug) {
    number
  }
}
    `;
export type CreateTableMutationFn = Apollo.MutationFunction<CreateTableMutation, CreateTableMutationVariables>;

/**
 * __useCreateTableMutation__
 *
 * To run a mutation, you first call `useCreateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTableMutation, { data, loading, error }] = useCreateTableMutation({
 *   variables: {
 *      table: // value for 'table'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useCreateTableMutation(baseOptions?: Apollo.MutationHookOptions<CreateTableMutation, CreateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTableMutation, CreateTableMutationVariables>(CreateTableDocument, options);
      }
export type CreateTableMutationHookResult = ReturnType<typeof useCreateTableMutation>;
export type CreateTableMutationResult = Apollo.MutationResult<CreateTableMutation>;
export type CreateTableMutationOptions = Apollo.BaseMutationOptions<CreateTableMutation, CreateTableMutationVariables>;
export const UpdateTableDocument = gql`
    mutation UpdateTable($table: TableUpdateInput!) {
  updateTable(table: $table) {
    number
  }
}
    `;
export type UpdateTableMutationFn = Apollo.MutationFunction<UpdateTableMutation, UpdateTableMutationVariables>;

/**
 * __useUpdateTableMutation__
 *
 * To run a mutation, you first call `useUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableMutation, { data, loading, error }] = useUpdateTableMutation({
 *   variables: {
 *      table: // value for 'table'
 *   },
 * });
 */
export function useUpdateTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTableMutation, UpdateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTableMutation, UpdateTableMutationVariables>(UpdateTableDocument, options);
      }
export type UpdateTableMutationHookResult = ReturnType<typeof useUpdateTableMutation>;
export type UpdateTableMutationResult = Apollo.MutationResult<UpdateTableMutation>;
export type UpdateTableMutationOptions = Apollo.BaseMutationOptions<UpdateTableMutation, UpdateTableMutationVariables>;
export const DeleteTableDocument = gql`
    mutation DeleteTable($id: String!) {
  deleteTable(id: $id) {
    number
  }
}
    `;
export type DeleteTableMutationFn = Apollo.MutationFunction<DeleteTableMutation, DeleteTableMutationVariables>;

/**
 * __useDeleteTableMutation__
 *
 * To run a mutation, you first call `useDeleteTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTableMutation, { data, loading, error }] = useDeleteTableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTableMutation, DeleteTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTableMutation, DeleteTableMutationVariables>(DeleteTableDocument, options);
      }
export type DeleteTableMutationHookResult = ReturnType<typeof useDeleteTableMutation>;
export type DeleteTableMutationResult = Apollo.MutationResult<DeleteTableMutation>;
export type DeleteTableMutationOptions = Apollo.BaseMutationOptions<DeleteTableMutation, DeleteTableMutationVariables>;
export const MenuCategoryDocument = gql`
    query MenuCategory($id: String!) {
  menuCategory(id: $id) {
    id
    name
    isActive
    menus {
      id
      name
    }
    menusOrder
  }
}
    `;

/**
 * __useMenuCategoryQuery__
 *
 * To run a query within a React component, call `useMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuCategoryQuery(baseOptions: Apollo.QueryHookOptions<MenuCategoryQuery, MenuCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuCategoryQuery, MenuCategoryQueryVariables>(MenuCategoryDocument, options);
      }
export function useMenuCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuCategoryQuery, MenuCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuCategoryQuery, MenuCategoryQueryVariables>(MenuCategoryDocument, options);
        }
export type MenuCategoryQueryHookResult = ReturnType<typeof useMenuCategoryQuery>;
export type MenuCategoryLazyQueryHookResult = ReturnType<typeof useMenuCategoryLazyQuery>;
export type MenuCategoryQueryResult = Apollo.QueryResult<MenuCategoryQuery, MenuCategoryQueryVariables>;
export const MenuCategoriesDocument = gql`
    query MenuCategories($locationSlug: String!) {
  menuCategories(locationSlug: $locationSlug) {
    id
    name
    isActive
    menus {
      id
      name
    }
    location {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useMenuCategoriesQuery__
 *
 * To run a query within a React component, call `useMenuCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuCategoriesQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useMenuCategoriesQuery(baseOptions: Apollo.QueryHookOptions<MenuCategoriesQuery, MenuCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuCategoriesQuery, MenuCategoriesQueryVariables>(MenuCategoriesDocument, options);
      }
export function useMenuCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuCategoriesQuery, MenuCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuCategoriesQuery, MenuCategoriesQueryVariables>(MenuCategoriesDocument, options);
        }
export type MenuCategoriesQueryHookResult = ReturnType<typeof useMenuCategoriesQuery>;
export type MenuCategoriesLazyQueryHookResult = ReturnType<typeof useMenuCategoriesLazyQuery>;
export type MenuCategoriesQueryResult = Apollo.QueryResult<MenuCategoriesQuery, MenuCategoriesQueryVariables>;
export const CreateMenuCategoryDocument = gql`
    mutation createMenuCategory($menuCategory: MenuCategoryCreateInput!, $locationSlug: String!) {
  createMenuCategory(menuCategory: $menuCategory, locationSlug: $locationSlug) {
    name
  }
}
    `;
export type CreateMenuCategoryMutationFn = Apollo.MutationFunction<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>;

/**
 * __useCreateMenuCategoryMutation__
 *
 * To run a mutation, you first call `useCreateMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuCategoryMutation, { data, loading, error }] = useCreateMenuCategoryMutation({
 *   variables: {
 *      menuCategory: // value for 'menuCategory'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useCreateMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>(CreateMenuCategoryDocument, options);
      }
export type CreateMenuCategoryMutationHookResult = ReturnType<typeof useCreateMenuCategoryMutation>;
export type CreateMenuCategoryMutationResult = Apollo.MutationResult<CreateMenuCategoryMutation>;
export type CreateMenuCategoryMutationOptions = Apollo.BaseMutationOptions<CreateMenuCategoryMutation, CreateMenuCategoryMutationVariables>;
export const UpdateMenuCategoryDocument = gql`
    mutation UpdateMenuCategory($menuCategory: MenuCategoryUpdateInput!) {
  updateMenuCategory(menuCategory: $menuCategory) {
    name
  }
}
    `;
export type UpdateMenuCategoryMutationFn = Apollo.MutationFunction<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>;

/**
 * __useUpdateMenuCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuCategoryMutation, { data, loading, error }] = useUpdateMenuCategoryMutation({
 *   variables: {
 *      menuCategory: // value for 'menuCategory'
 *   },
 * });
 */
export function useUpdateMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>(UpdateMenuCategoryDocument, options);
      }
export type UpdateMenuCategoryMutationHookResult = ReturnType<typeof useUpdateMenuCategoryMutation>;
export type UpdateMenuCategoryMutationResult = Apollo.MutationResult<UpdateMenuCategoryMutation>;
export type UpdateMenuCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateMenuCategoryMutation, UpdateMenuCategoryMutationVariables>;
export const UpdateMenusInMenuCategoryDocument = gql`
    mutation UpdateMenusInMenuCategory($menuCategory: MenusInMenuCategoryUpdateInput!) {
  updateMenusInMenuCategory(menuCategory: $menuCategory) {
    name
  }
}
    `;
export type UpdateMenusInMenuCategoryMutationFn = Apollo.MutationFunction<UpdateMenusInMenuCategoryMutation, UpdateMenusInMenuCategoryMutationVariables>;

/**
 * __useUpdateMenusInMenuCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateMenusInMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenusInMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenusInMenuCategoryMutation, { data, loading, error }] = useUpdateMenusInMenuCategoryMutation({
 *   variables: {
 *      menuCategory: // value for 'menuCategory'
 *   },
 * });
 */
export function useUpdateMenusInMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenusInMenuCategoryMutation, UpdateMenusInMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenusInMenuCategoryMutation, UpdateMenusInMenuCategoryMutationVariables>(UpdateMenusInMenuCategoryDocument, options);
      }
export type UpdateMenusInMenuCategoryMutationHookResult = ReturnType<typeof useUpdateMenusInMenuCategoryMutation>;
export type UpdateMenusInMenuCategoryMutationResult = Apollo.MutationResult<UpdateMenusInMenuCategoryMutation>;
export type UpdateMenusInMenuCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateMenusInMenuCategoryMutation, UpdateMenusInMenuCategoryMutationVariables>;
export const DeleteMenuCategoryDocument = gql`
    mutation DeleteMenuCategory($id: String!) {
  deleteMenuCategory(id: $id) {
    name
  }
}
    `;
export type DeleteMenuCategoryMutationFn = Apollo.MutationFunction<DeleteMenuCategoryMutation, DeleteMenuCategoryMutationVariables>;

/**
 * __useDeleteMenuCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuCategoryMutation, { data, loading, error }] = useDeleteMenuCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuCategoryMutation, DeleteMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuCategoryMutation, DeleteMenuCategoryMutationVariables>(DeleteMenuCategoryDocument, options);
      }
export type DeleteMenuCategoryMutationHookResult = ReturnType<typeof useDeleteMenuCategoryMutation>;
export type DeleteMenuCategoryMutationResult = Apollo.MutationResult<DeleteMenuCategoryMutation>;
export type DeleteMenuCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteMenuCategoryMutation, DeleteMenuCategoryMutationVariables>;
export const MenuDocument = gql`
    query Menu($id: String!) {
  menu(id: $id) {
    id
    name
    isActive
    itemCategories {
      id
      name
    }
    itemAndItemCategoriesOrder
    items {
      id
      name
      price
    }
    itemsOrder
    menuCategory {
      id
      name
    }
  }
}
    `;

/**
 * __useMenuQuery__
 *
 * To run a query within a React component, call `useMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuQuery(baseOptions: Apollo.QueryHookOptions<MenuQuery, MenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
      }
export function useMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuQuery, MenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
        }
export type MenuQueryHookResult = ReturnType<typeof useMenuQuery>;
export type MenuLazyQueryHookResult = ReturnType<typeof useMenuLazyQuery>;
export type MenuQueryResult = Apollo.QueryResult<MenuQuery, MenuQueryVariables>;
export const MenusDocument = gql`
    query Menus($locationSlug: String!) {
  menus(locationSlug: $locationSlug) {
    id
    name
    menuCategoryId
    isActive
    itemCategories {
      id
      name
    }
    items {
      id
      name
      price
    }
    menuCategory {
      id
      name
    }
  }
}
    `;

/**
 * __useMenusQuery__
 *
 * To run a query within a React component, call `useMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenusQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useMenusQuery(baseOptions: Apollo.QueryHookOptions<MenusQuery, MenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenusQuery, MenusQueryVariables>(MenusDocument, options);
      }
export function useMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenusQuery, MenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenusQuery, MenusQueryVariables>(MenusDocument, options);
        }
export type MenusQueryHookResult = ReturnType<typeof useMenusQuery>;
export type MenusLazyQueryHookResult = ReturnType<typeof useMenusLazyQuery>;
export type MenusQueryResult = Apollo.QueryResult<MenusQuery, MenusQueryVariables>;
export const CreateMenuDocument = gql`
    mutation CreateMenu($menu: MenuCreateInput!, $locationSlug: String!) {
  createMenu(menu: $menu, locationSlug: $locationSlug) {
    name
  }
}
    `;
export type CreateMenuMutationFn = Apollo.MutationFunction<CreateMenuMutation, CreateMenuMutationVariables>;

/**
 * __useCreateMenuMutation__
 *
 * To run a mutation, you first call `useCreateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuMutation, { data, loading, error }] = useCreateMenuMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useCreateMenuMutation(baseOptions?: Apollo.MutationHookOptions<CreateMenuMutation, CreateMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMenuMutation, CreateMenuMutationVariables>(CreateMenuDocument, options);
      }
export type CreateMenuMutationHookResult = ReturnType<typeof useCreateMenuMutation>;
export type CreateMenuMutationResult = Apollo.MutationResult<CreateMenuMutation>;
export type CreateMenuMutationOptions = Apollo.BaseMutationOptions<CreateMenuMutation, CreateMenuMutationVariables>;
export const UpdateMenuDocument = gql`
    mutation UpdateMenu($menu: MenuUpdateInput!) {
  updateMenu(menu: $menu) {
    name
  }
}
    `;
export type UpdateMenuMutationFn = Apollo.MutationFunction<UpdateMenuMutation, UpdateMenuMutationVariables>;

/**
 * __useUpdateMenuMutation__
 *
 * To run a mutation, you first call `useUpdateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuMutation, { data, loading, error }] = useUpdateMenuMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useUpdateMenuMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenuMutation, UpdateMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenuMutation, UpdateMenuMutationVariables>(UpdateMenuDocument, options);
      }
export type UpdateMenuMutationHookResult = ReturnType<typeof useUpdateMenuMutation>;
export type UpdateMenuMutationResult = Apollo.MutationResult<UpdateMenuMutation>;
export type UpdateMenuMutationOptions = Apollo.BaseMutationOptions<UpdateMenuMutation, UpdateMenuMutationVariables>;
export const UpdateItemCategoriesInMenuDocument = gql`
    mutation UpdateItemCategoriesInMenu($menu: ItemCategoriesInMenuUpdateInput!) {
  updateItemCategoriesInMenu(menu: $menu) {
    name
  }
}
    `;
export type UpdateItemCategoriesInMenuMutationFn = Apollo.MutationFunction<UpdateItemCategoriesInMenuMutation, UpdateItemCategoriesInMenuMutationVariables>;

/**
 * __useUpdateItemCategoriesInMenuMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoriesInMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoriesInMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoriesInMenuMutation, { data, loading, error }] = useUpdateItemCategoriesInMenuMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useUpdateItemCategoriesInMenuMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemCategoriesInMenuMutation, UpdateItemCategoriesInMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemCategoriesInMenuMutation, UpdateItemCategoriesInMenuMutationVariables>(UpdateItemCategoriesInMenuDocument, options);
      }
export type UpdateItemCategoriesInMenuMutationHookResult = ReturnType<typeof useUpdateItemCategoriesInMenuMutation>;
export type UpdateItemCategoriesInMenuMutationResult = Apollo.MutationResult<UpdateItemCategoriesInMenuMutation>;
export type UpdateItemCategoriesInMenuMutationOptions = Apollo.BaseMutationOptions<UpdateItemCategoriesInMenuMutation, UpdateItemCategoriesInMenuMutationVariables>;
export const UpdateItemsInMenuDocument = gql`
    mutation UpdateItemsInMenu($menu: ItemsInMenuUpdateInput!) {
  updateItemsInMenu(menu: $menu) {
    name
  }
}
    `;
export type UpdateItemsInMenuMutationFn = Apollo.MutationFunction<UpdateItemsInMenuMutation, UpdateItemsInMenuMutationVariables>;

/**
 * __useUpdateItemsInMenuMutation__
 *
 * To run a mutation, you first call `useUpdateItemsInMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemsInMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemsInMenuMutation, { data, loading, error }] = useUpdateItemsInMenuMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useUpdateItemsInMenuMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemsInMenuMutation, UpdateItemsInMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemsInMenuMutation, UpdateItemsInMenuMutationVariables>(UpdateItemsInMenuDocument, options);
      }
export type UpdateItemsInMenuMutationHookResult = ReturnType<typeof useUpdateItemsInMenuMutation>;
export type UpdateItemsInMenuMutationResult = Apollo.MutationResult<UpdateItemsInMenuMutation>;
export type UpdateItemsInMenuMutationOptions = Apollo.BaseMutationOptions<UpdateItemsInMenuMutation, UpdateItemsInMenuMutationVariables>;
export const DeleteMenuDocument = gql`
    mutation DeleteMenu($id: String!) {
  deleteMenu(id: $id) {
    name
  }
}
    `;
export type DeleteMenuMutationFn = Apollo.MutationFunction<DeleteMenuMutation, DeleteMenuMutationVariables>;

/**
 * __useDeleteMenuMutation__
 *
 * To run a mutation, you first call `useDeleteMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuMutation, { data, loading, error }] = useDeleteMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMenuMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMenuMutation, DeleteMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMenuMutation, DeleteMenuMutationVariables>(DeleteMenuDocument, options);
      }
export type DeleteMenuMutationHookResult = ReturnType<typeof useDeleteMenuMutation>;
export type DeleteMenuMutationResult = Apollo.MutationResult<DeleteMenuMutation>;
export type DeleteMenuMutationOptions = Apollo.BaseMutationOptions<DeleteMenuMutation, DeleteMenuMutationVariables>;
export const ItemCategoriesDocument = gql`
    query ItemCategories($locationSlug: String!) {
  itemCategories(locationSlug: $locationSlug) {
    id
    name
    isActive
    items {
      id
      name
      price
      image
    }
    menu {
      id
      name
    }
  }
}
    `;

/**
 * __useItemCategoriesQuery__
 *
 * To run a query within a React component, call `useItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategoriesQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useItemCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemCategoriesQuery, ItemCategoriesQueryVariables>(ItemCategoriesDocument, options);
      }
export function useItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemCategoriesQuery, ItemCategoriesQueryVariables>(ItemCategoriesDocument, options);
        }
export type ItemCategoriesQueryHookResult = ReturnType<typeof useItemCategoriesQuery>;
export type ItemCategoriesLazyQueryHookResult = ReturnType<typeof useItemCategoriesLazyQuery>;
export type ItemCategoriesQueryResult = Apollo.QueryResult<ItemCategoriesQuery, ItemCategoriesQueryVariables>;
export const ItemCategoryDocument = gql`
    query ItemCategory($id: String!) {
  itemCategory(id: $id) {
    id
    name
    isActive
    items {
      id
      name
      price
      image
    }
    itemsOrder
    menu {
      id
      name
    }
  }
}
    `;

/**
 * __useItemCategoryQuery__
 *
 * To run a query within a React component, call `useItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemCategoryQuery(baseOptions: Apollo.QueryHookOptions<ItemCategoryQuery, ItemCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemCategoryQuery, ItemCategoryQueryVariables>(ItemCategoryDocument, options);
      }
export function useItemCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCategoryQuery, ItemCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemCategoryQuery, ItemCategoryQueryVariables>(ItemCategoryDocument, options);
        }
export type ItemCategoryQueryHookResult = ReturnType<typeof useItemCategoryQuery>;
export type ItemCategoryLazyQueryHookResult = ReturnType<typeof useItemCategoryLazyQuery>;
export type ItemCategoryQueryResult = Apollo.QueryResult<ItemCategoryQuery, ItemCategoryQueryVariables>;
export const CreateItemCategoryDocument = gql`
    mutation CreateItemCategory($itemCategory: ItemCategoryCreateInput!, $locationSlug: String!) {
  createItemCategory(itemCategory: $itemCategory, locationSlug: $locationSlug) {
    name
  }
}
    `;
export type CreateItemCategoryMutationFn = Apollo.MutationFunction<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>;

/**
 * __useCreateItemCategoryMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMutation, { data, loading, error }] = useCreateItemCategoryMutation({
 *   variables: {
 *      itemCategory: // value for 'itemCategory'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useCreateItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>(CreateItemCategoryDocument, options);
      }
export type CreateItemCategoryMutationHookResult = ReturnType<typeof useCreateItemCategoryMutation>;
export type CreateItemCategoryMutationResult = Apollo.MutationResult<CreateItemCategoryMutation>;
export type CreateItemCategoryMutationOptions = Apollo.BaseMutationOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>;
export const UpdateItemCategoryDocument = gql`
    mutation UpdateItemCategory($itemCategory: ItemCategoryUpdateInput!) {
  updateItemCategory(itemCategory: $itemCategory) {
    name
  }
}
    `;
export type UpdateItemCategoryMutationFn = Apollo.MutationFunction<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>;

/**
 * __useUpdateItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryMutation, { data, loading, error }] = useUpdateItemCategoryMutation({
 *   variables: {
 *      itemCategory: // value for 'itemCategory'
 *   },
 * });
 */
export function useUpdateItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>(UpdateItemCategoryDocument, options);
      }
export type UpdateItemCategoryMutationHookResult = ReturnType<typeof useUpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationResult = Apollo.MutationResult<UpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>;
export const UpdateItemsInItemCategoryDocument = gql`
    mutation UpdateItemsInItemCategory($itemCategory: ItemsInItemCategoryUpdateInput!) {
  updateItemsInItemCategory(itemCategory: $itemCategory) {
    name
  }
}
    `;
export type UpdateItemsInItemCategoryMutationFn = Apollo.MutationFunction<UpdateItemsInItemCategoryMutation, UpdateItemsInItemCategoryMutationVariables>;

/**
 * __useUpdateItemsInItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemsInItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemsInItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemsInItemCategoryMutation, { data, loading, error }] = useUpdateItemsInItemCategoryMutation({
 *   variables: {
 *      itemCategory: // value for 'itemCategory'
 *   },
 * });
 */
export function useUpdateItemsInItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemsInItemCategoryMutation, UpdateItemsInItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemsInItemCategoryMutation, UpdateItemsInItemCategoryMutationVariables>(UpdateItemsInItemCategoryDocument, options);
      }
export type UpdateItemsInItemCategoryMutationHookResult = ReturnType<typeof useUpdateItemsInItemCategoryMutation>;
export type UpdateItemsInItemCategoryMutationResult = Apollo.MutationResult<UpdateItemsInItemCategoryMutation>;
export type UpdateItemsInItemCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateItemsInItemCategoryMutation, UpdateItemsInItemCategoryMutationVariables>;
export const DeleteItemCategoryDocument = gql`
    mutation DeleteItemCategory($id: String!) {
  deleteItemCategory(id: $id) {
    name
  }
}
    `;
export type DeleteItemCategoryMutationFn = Apollo.MutationFunction<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>;

/**
 * __useDeleteItemCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryMutation, { data, loading, error }] = useDeleteItemCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>(DeleteItemCategoryDocument, options);
      }
export type DeleteItemCategoryMutationHookResult = ReturnType<typeof useDeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationResult = Apollo.MutationResult<DeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>;
export const ItemsDocument = gql`
    query Items($locationSlug: String!) {
  items(locationSlug: $locationSlug) {
    id
    name
    price
    description
    backgroundInformation
    image
    isActive
    itemCategory {
      id
      name
    }
    menu {
      id
      name
    }
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useItemsQuery(baseOptions: Apollo.QueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
      }
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
        }
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>;
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>;
export type ItemsQueryResult = Apollo.QueryResult<ItemsQuery, ItemsQueryVariables>;
export const ItemDocument = gql`
    query Item($id: String!) {
  item(id: $id) {
    id
    name
    price
    description
    backgroundInformation
    image
    isActive
    tags {
      id
      name
    }
    optionGroupsOrder
    itemCategory {
      id
      name
    }
    menu {
      id
      name
    }
  }
}
    `;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQuery, ItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
      }
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemQuery, ItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
        }
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>;
export const CreateItemDocument = gql`
    mutation CreateItem($item: ItemCreateInput!, $locationSlug: String!) {
  createItem(item: $item, locationSlug: $locationSlug) {
    name
  }
}
    `;
export type CreateItemMutationFn = Apollo.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($item: ItemUpdateInput!) {
  updateItem(item: $item) {
    name
  }
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const UpdateItemOptionsDocument = gql`
    mutation UpdateItemOptions($item: ItemOptionsUpdateInput!) {
  updateItemOptions(item: $item) {
    name
  }
}
    `;
export type UpdateItemOptionsMutationFn = Apollo.MutationFunction<UpdateItemOptionsMutation, UpdateItemOptionsMutationVariables>;

/**
 * __useUpdateItemOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateItemOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemOptionsMutation, { data, loading, error }] = useUpdateItemOptionsMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemOptionsMutation, UpdateItemOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemOptionsMutation, UpdateItemOptionsMutationVariables>(UpdateItemOptionsDocument, options);
      }
export type UpdateItemOptionsMutationHookResult = ReturnType<typeof useUpdateItemOptionsMutation>;
export type UpdateItemOptionsMutationResult = Apollo.MutationResult<UpdateItemOptionsMutation>;
export type UpdateItemOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateItemOptionsMutation, UpdateItemOptionsMutationVariables>;
export const UpdateItemBackgroundInformationDocument = gql`
    mutation UpdateItemBackgroundInformation($item: ItemBackgroundInformationUpdateInput!) {
  updateItemBackgroundInformation(item: $item) {
    name
  }
}
    `;
export type UpdateItemBackgroundInformationMutationFn = Apollo.MutationFunction<UpdateItemBackgroundInformationMutation, UpdateItemBackgroundInformationMutationVariables>;

/**
 * __useUpdateItemBackgroundInformationMutation__
 *
 * To run a mutation, you first call `useUpdateItemBackgroundInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemBackgroundInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemBackgroundInformationMutation, { data, loading, error }] = useUpdateItemBackgroundInformationMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemBackgroundInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemBackgroundInformationMutation, UpdateItemBackgroundInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemBackgroundInformationMutation, UpdateItemBackgroundInformationMutationVariables>(UpdateItemBackgroundInformationDocument, options);
      }
export type UpdateItemBackgroundInformationMutationHookResult = ReturnType<typeof useUpdateItemBackgroundInformationMutation>;
export type UpdateItemBackgroundInformationMutationResult = Apollo.MutationResult<UpdateItemBackgroundInformationMutation>;
export type UpdateItemBackgroundInformationMutationOptions = Apollo.BaseMutationOptions<UpdateItemBackgroundInformationMutation, UpdateItemBackgroundInformationMutationVariables>;
export const DeleteItemDocument = gql`
    mutation DeleteItem($id: String!) {
  deleteItem(id: $id) {
    name
  }
}
    `;
export type DeleteItemMutationFn = Apollo.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, options);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const OptionGroupsDocument = gql`
    query OptionGroups($itemId: String!) {
  optionGroups(itemId: $itemId) {
    id
    name
    isMultiSelect
    isAdditive
    options {
      id
      name
      price
    }
  }
}
    `;

/**
 * __useOptionGroupsQuery__
 *
 * To run a query within a React component, call `useOptionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionGroupsQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useOptionGroupsQuery(baseOptions: Apollo.QueryHookOptions<OptionGroupsQuery, OptionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionGroupsQuery, OptionGroupsQueryVariables>(OptionGroupsDocument, options);
      }
export function useOptionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionGroupsQuery, OptionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionGroupsQuery, OptionGroupsQueryVariables>(OptionGroupsDocument, options);
        }
export type OptionGroupsQueryHookResult = ReturnType<typeof useOptionGroupsQuery>;
export type OptionGroupsLazyQueryHookResult = ReturnType<typeof useOptionGroupsLazyQuery>;
export type OptionGroupsQueryResult = Apollo.QueryResult<OptionGroupsQuery, OptionGroupsQueryVariables>;
export const OptionGroupDocument = gql`
    query OptionGroup($id: String!) {
  optionGroup(id: $id) {
    id
    name
    isMultiSelect
    isAdditive
    options {
      id
      name
      price
    }
  }
}
    `;

/**
 * __useOptionGroupQuery__
 *
 * To run a query within a React component, call `useOptionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptionGroupQuery(baseOptions: Apollo.QueryHookOptions<OptionGroupQuery, OptionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionGroupQuery, OptionGroupQueryVariables>(OptionGroupDocument, options);
      }
export function useOptionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionGroupQuery, OptionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionGroupQuery, OptionGroupQueryVariables>(OptionGroupDocument, options);
        }
export type OptionGroupQueryHookResult = ReturnType<typeof useOptionGroupQuery>;
export type OptionGroupLazyQueryHookResult = ReturnType<typeof useOptionGroupLazyQuery>;
export type OptionGroupQueryResult = Apollo.QueryResult<OptionGroupQuery, OptionGroupQueryVariables>;
export const CreateOptionGroupDocument = gql`
    mutation CreateOptionGroup($optionGroup: OptionGroupCreateInput!, $itemId: String!) {
  createOptionGroup(optionGroup: $optionGroup, itemId: $itemId) {
    name
  }
}
    `;
export type CreateOptionGroupMutationFn = Apollo.MutationFunction<CreateOptionGroupMutation, CreateOptionGroupMutationVariables>;

/**
 * __useCreateOptionGroupMutation__
 *
 * To run a mutation, you first call `useCreateOptionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionGroupMutation, { data, loading, error }] = useCreateOptionGroupMutation({
 *   variables: {
 *      optionGroup: // value for 'optionGroup'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useCreateOptionGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateOptionGroupMutation, CreateOptionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOptionGroupMutation, CreateOptionGroupMutationVariables>(CreateOptionGroupDocument, options);
      }
export type CreateOptionGroupMutationHookResult = ReturnType<typeof useCreateOptionGroupMutation>;
export type CreateOptionGroupMutationResult = Apollo.MutationResult<CreateOptionGroupMutation>;
export type CreateOptionGroupMutationOptions = Apollo.BaseMutationOptions<CreateOptionGroupMutation, CreateOptionGroupMutationVariables>;
export const UpdateOptionGroupDocument = gql`
    mutation UpdateOptionGroup($optionGroup: OptionGroupUpdateInput!, $itemId: String!) {
  updateOptionGroup(optionGroup: $optionGroup, itemId: $itemId) {
    name
  }
}
    `;
export type UpdateOptionGroupMutationFn = Apollo.MutationFunction<UpdateOptionGroupMutation, UpdateOptionGroupMutationVariables>;

/**
 * __useUpdateOptionGroupMutation__
 *
 * To run a mutation, you first call `useUpdateOptionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionGroupMutation, { data, loading, error }] = useUpdateOptionGroupMutation({
 *   variables: {
 *      optionGroup: // value for 'optionGroup'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useUpdateOptionGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptionGroupMutation, UpdateOptionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptionGroupMutation, UpdateOptionGroupMutationVariables>(UpdateOptionGroupDocument, options);
      }
export type UpdateOptionGroupMutationHookResult = ReturnType<typeof useUpdateOptionGroupMutation>;
export type UpdateOptionGroupMutationResult = Apollo.MutationResult<UpdateOptionGroupMutation>;
export type UpdateOptionGroupMutationOptions = Apollo.BaseMutationOptions<UpdateOptionGroupMutation, UpdateOptionGroupMutationVariables>;
export const DeleteOptionGroupDocument = gql`
    mutation DeleteOptionGroup($id: String!, $itemId: String!) {
  deleteOptionGroup(id: $id, itemId: $itemId) {
    name
  }
}
    `;
export type DeleteOptionGroupMutationFn = Apollo.MutationFunction<DeleteOptionGroupMutation, DeleteOptionGroupMutationVariables>;

/**
 * __useDeleteOptionGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOptionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionGroupMutation, { data, loading, error }] = useDeleteOptionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useDeleteOptionGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOptionGroupMutation, DeleteOptionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOptionGroupMutation, DeleteOptionGroupMutationVariables>(DeleteOptionGroupDocument, options);
      }
export type DeleteOptionGroupMutationHookResult = ReturnType<typeof useDeleteOptionGroupMutation>;
export type DeleteOptionGroupMutationResult = Apollo.MutationResult<DeleteOptionGroupMutation>;
export type DeleteOptionGroupMutationOptions = Apollo.BaseMutationOptions<DeleteOptionGroupMutation, DeleteOptionGroupMutationVariables>;
export const TimeControlDocument = gql`
    query TimeControl($itemId: String!) {
  timeControl(itemId: $itemId) {
    id
    isActive
    weekDays {
      id
      isActive
      index
      timeSlots {
        id
        startTime
        endTime
      }
    }
  }
}
    `;

/**
 * __useTimeControlQuery__
 *
 * To run a query within a React component, call `useTimeControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeControlQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useTimeControlQuery(baseOptions: Apollo.QueryHookOptions<TimeControlQuery, TimeControlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeControlQuery, TimeControlQueryVariables>(TimeControlDocument, options);
      }
export function useTimeControlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeControlQuery, TimeControlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeControlQuery, TimeControlQueryVariables>(TimeControlDocument, options);
        }
export type TimeControlQueryHookResult = ReturnType<typeof useTimeControlQuery>;
export type TimeControlLazyQueryHookResult = ReturnType<typeof useTimeControlLazyQuery>;
export type TimeControlQueryResult = Apollo.QueryResult<TimeControlQuery, TimeControlQueryVariables>;
export const UpsertTimeControlDocument = gql`
    mutation UpsertTimeControl($timeControl: TimeControlUpsertInput!, $itemId: String!) {
  upsertTimeControl(timeControl: $timeControl, itemId: $itemId) {
    id
    isActive
  }
}
    `;
export type UpsertTimeControlMutationFn = Apollo.MutationFunction<UpsertTimeControlMutation, UpsertTimeControlMutationVariables>;

/**
 * __useUpsertTimeControlMutation__
 *
 * To run a mutation, you first call `useUpsertTimeControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTimeControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTimeControlMutation, { data, loading, error }] = useUpsertTimeControlMutation({
 *   variables: {
 *      timeControl: // value for 'timeControl'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useUpsertTimeControlMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTimeControlMutation, UpsertTimeControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTimeControlMutation, UpsertTimeControlMutationVariables>(UpsertTimeControlDocument, options);
      }
export type UpsertTimeControlMutationHookResult = ReturnType<typeof useUpsertTimeControlMutation>;
export type UpsertTimeControlMutationResult = Apollo.MutationResult<UpsertTimeControlMutation>;
export type UpsertTimeControlMutationOptions = Apollo.BaseMutationOptions<UpsertTimeControlMutation, UpsertTimeControlMutationVariables>;
export const TagCategoriesDocument = gql`
    query TagCategories($locationSlug: String!) {
  tagCategories(locationSlug: $locationSlug) {
    id
    name
    location {
      id
      name
      slug
    }
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useTagCategoriesQuery__
 *
 * To run a query within a React component, call `useTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoriesQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useTagCategoriesQuery(baseOptions: Apollo.QueryHookOptions<TagCategoriesQuery, TagCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagCategoriesQuery, TagCategoriesQueryVariables>(TagCategoriesDocument, options);
      }
export function useTagCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagCategoriesQuery, TagCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagCategoriesQuery, TagCategoriesQueryVariables>(TagCategoriesDocument, options);
        }
export type TagCategoriesQueryHookResult = ReturnType<typeof useTagCategoriesQuery>;
export type TagCategoriesLazyQueryHookResult = ReturnType<typeof useTagCategoriesLazyQuery>;
export type TagCategoriesQueryResult = Apollo.QueryResult<TagCategoriesQuery, TagCategoriesQueryVariables>;
export const TagCategoryDocument = gql`
    query TagCategory($id: String!) {
  tagCategory(id: $id) {
    id
    name
    location {
      id
      name
      slug
    }
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useTagCategoryQuery__
 *
 * To run a query within a React component, call `useTagCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagCategoryQuery(baseOptions: Apollo.QueryHookOptions<TagCategoryQuery, TagCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagCategoryQuery, TagCategoryQueryVariables>(TagCategoryDocument, options);
      }
export function useTagCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagCategoryQuery, TagCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagCategoryQuery, TagCategoryQueryVariables>(TagCategoryDocument, options);
        }
export type TagCategoryQueryHookResult = ReturnType<typeof useTagCategoryQuery>;
export type TagCategoryLazyQueryHookResult = ReturnType<typeof useTagCategoryLazyQuery>;
export type TagCategoryQueryResult = Apollo.QueryResult<TagCategoryQuery, TagCategoryQueryVariables>;
export const CreateTagCategoryDocument = gql`
    mutation CreateTagCategory($tagCategory: TagCategoryCreateInput!, $locationSlug: String!) {
  createTagCategory(tagCategory: $tagCategory, locationSlug: $locationSlug) {
    name
  }
}
    `;
export type CreateTagCategoryMutationFn = Apollo.MutationFunction<CreateTagCategoryMutation, CreateTagCategoryMutationVariables>;

/**
 * __useCreateTagCategoryMutation__
 *
 * To run a mutation, you first call `useCreateTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagCategoryMutation, { data, loading, error }] = useCreateTagCategoryMutation({
 *   variables: {
 *      tagCategory: // value for 'tagCategory'
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useCreateTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagCategoryMutation, CreateTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagCategoryMutation, CreateTagCategoryMutationVariables>(CreateTagCategoryDocument, options);
      }
export type CreateTagCategoryMutationHookResult = ReturnType<typeof useCreateTagCategoryMutation>;
export type CreateTagCategoryMutationResult = Apollo.MutationResult<CreateTagCategoryMutation>;
export type CreateTagCategoryMutationOptions = Apollo.BaseMutationOptions<CreateTagCategoryMutation, CreateTagCategoryMutationVariables>;
export const UpdateTagCategoryDocument = gql`
    mutation UpdateTagCategory($tagCategory: TagCategoryUpdateInput!) {
  updateTagCategory(tagCategory: $tagCategory) {
    name
  }
}
    `;
export type UpdateTagCategoryMutationFn = Apollo.MutationFunction<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>;

/**
 * __useUpdateTagCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagCategoryMutation, { data, loading, error }] = useUpdateTagCategoryMutation({
 *   variables: {
 *      tagCategory: // value for 'tagCategory'
 *   },
 * });
 */
export function useUpdateTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>(UpdateTagCategoryDocument, options);
      }
export type UpdateTagCategoryMutationHookResult = ReturnType<typeof useUpdateTagCategoryMutation>;
export type UpdateTagCategoryMutationResult = Apollo.MutationResult<UpdateTagCategoryMutation>;
export type UpdateTagCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateTagCategoryMutation, UpdateTagCategoryMutationVariables>;
export const DeleteTagCategoryDocument = gql`
    mutation DeleteTagCategory($id: String!) {
  deleteTagCategory(id: $id) {
    name
  }
}
    `;
export type DeleteTagCategoryMutationFn = Apollo.MutationFunction<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>;

/**
 * __useDeleteTagCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagCategoryMutation, { data, loading, error }] = useDeleteTagCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>(DeleteTagCategoryDocument, options);
      }
export type DeleteTagCategoryMutationHookResult = ReturnType<typeof useDeleteTagCategoryMutation>;
export type DeleteTagCategoryMutationResult = Apollo.MutationResult<DeleteTagCategoryMutation>;
export type DeleteTagCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteTagCategoryMutation, DeleteTagCategoryMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!, $path: String!) {
  uploadFile(file: $file, path: $path) {
    fileUrl
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const TablesWithOrdersDocument = gql`
    query TablesWithOrders($locationSlug: String!) {
  tablesWithOrders(locationSlug: $locationSlug) {
    id
    number
    name
    orders {
      id
      orderNumber
      items {
        id
        name
        category
        price
        quantity
        notes
        refItemId
        posId
        isSentToPos
        options {
          id
          name
          group
          price
          posId
          quantity
        }
      }
      notes
      status
      createdAt
      payment {
        id
        amount
        createdAt
        method
        status
        tip
      }
    }
    waiterCalls {
      id
      createdAt
      notes
      status
    }
  }
}
    `;

/**
 * __useTablesWithOrdersQuery__
 *
 * To run a query within a React component, call `useTablesWithOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTablesWithOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTablesWithOrdersQuery({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useTablesWithOrdersQuery(baseOptions: Apollo.QueryHookOptions<TablesWithOrdersQuery, TablesWithOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TablesWithOrdersQuery, TablesWithOrdersQueryVariables>(TablesWithOrdersDocument, options);
      }
export function useTablesWithOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TablesWithOrdersQuery, TablesWithOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TablesWithOrdersQuery, TablesWithOrdersQueryVariables>(TablesWithOrdersDocument, options);
        }
export type TablesWithOrdersQueryHookResult = ReturnType<typeof useTablesWithOrdersQuery>;
export type TablesWithOrdersLazyQueryHookResult = ReturnType<typeof useTablesWithOrdersLazyQuery>;
export type TablesWithOrdersQueryResult = Apollo.QueryResult<TablesWithOrdersQuery, TablesWithOrdersQueryVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($order: OrderUpdateInput!) {
  updateOrder(order: $order) {
    id
    status
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateOrderItemDocument = gql`
    mutation UpdateOrderItem($orderItem: OrderItemUpdateInput!) {
  updateOrderItem(orderItem: $orderItem) {
    id
  }
}
    `;
export type UpdateOrderItemMutationFn = Apollo.MutationFunction<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;

/**
 * __useUpdateOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemMutation, { data, loading, error }] = useUpdateOrderItemMutation({
 *   variables: {
 *      orderItem: // value for 'orderItem'
 *   },
 * });
 */
export function useUpdateOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>(UpdateOrderItemDocument, options);
      }
export type UpdateOrderItemMutationHookResult = ReturnType<typeof useUpdateOrderItemMutation>;
export type UpdateOrderItemMutationResult = Apollo.MutationResult<UpdateOrderItemMutation>;
export type UpdateOrderItemMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;
export const UpdateWaiterCallDocument = gql`
    mutation UpdateWaiterCall($waiterCall: WaiterCallUpdateInput!) {
  updateWaiterCall(waiterCall: $waiterCall) {
    id
    status
  }
}
    `;
export type UpdateWaiterCallMutationFn = Apollo.MutationFunction<UpdateWaiterCallMutation, UpdateWaiterCallMutationVariables>;

/**
 * __useUpdateWaiterCallMutation__
 *
 * To run a mutation, you first call `useUpdateWaiterCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWaiterCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWaiterCallMutation, { data, loading, error }] = useUpdateWaiterCallMutation({
 *   variables: {
 *      waiterCall: // value for 'waiterCall'
 *   },
 * });
 */
export function useUpdateWaiterCallMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWaiterCallMutation, UpdateWaiterCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWaiterCallMutation, UpdateWaiterCallMutationVariables>(UpdateWaiterCallDocument, options);
      }
export type UpdateWaiterCallMutationHookResult = ReturnType<typeof useUpdateWaiterCallMutation>;
export type UpdateWaiterCallMutationResult = Apollo.MutationResult<UpdateWaiterCallMutation>;
export type UpdateWaiterCallMutationOptions = Apollo.BaseMutationOptions<UpdateWaiterCallMutation, UpdateWaiterCallMutationVariables>;
export const OnOrderCreatedDocument = gql`
    subscription OnOrderCreated {
  orderCreated
}
    `;

/**
 * __useOnOrderCreatedSubscription__
 *
 * To run a query within a React component, call `useOnOrderCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOrderCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOrderCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnOrderCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnOrderCreatedSubscription, OnOrderCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnOrderCreatedSubscription, OnOrderCreatedSubscriptionVariables>(OnOrderCreatedDocument, options);
      }
export type OnOrderCreatedSubscriptionHookResult = ReturnType<typeof useOnOrderCreatedSubscription>;
export type OnOrderCreatedSubscriptionResult = Apollo.SubscriptionResult<OnOrderCreatedSubscription>;
export const OnWaiterCallCreatedDocument = gql`
    subscription OnWaiterCallCreated {
  waiterCallCreated
}
    `;

/**
 * __useOnWaiterCallCreatedSubscription__
 *
 * To run a query within a React component, call `useOnWaiterCallCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWaiterCallCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWaiterCallCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnWaiterCallCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnWaiterCallCreatedSubscription, OnWaiterCallCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWaiterCallCreatedSubscription, OnWaiterCallCreatedSubscriptionVariables>(OnWaiterCallCreatedDocument, options);
      }
export type OnWaiterCallCreatedSubscriptionHookResult = ReturnType<typeof useOnWaiterCallCreatedSubscription>;
export type OnWaiterCallCreatedSubscriptionResult = Apollo.SubscriptionResult<OnWaiterCallCreatedSubscription>;
export const TranslateTextDocument = gql`
    mutation TranslateText($translation: TranslationInput!) {
  translateText(translation: $translation)
}
    `;
export type TranslateTextMutationFn = Apollo.MutationFunction<TranslateTextMutation, TranslateTextMutationVariables>;

/**
 * __useTranslateTextMutation__
 *
 * To run a mutation, you first call `useTranslateTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateTextMutation, { data, loading, error }] = useTranslateTextMutation({
 *   variables: {
 *      translation: // value for 'translation'
 *   },
 * });
 */
export function useTranslateTextMutation(baseOptions?: Apollo.MutationHookOptions<TranslateTextMutation, TranslateTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TranslateTextMutation, TranslateTextMutationVariables>(TranslateTextDocument, options);
      }
export type TranslateTextMutationHookResult = ReturnType<typeof useTranslateTextMutation>;
export type TranslateTextMutationResult = Apollo.MutationResult<TranslateTextMutation>;
export type TranslateTextMutationOptions = Apollo.BaseMutationOptions<TranslateTextMutation, TranslateTextMutationVariables>;
export const TranslateMenuCardDocument = gql`
    mutation translateMenuCard($locationSlug: String!) {
  translateMenuCard(locationSlug: $locationSlug)
}
    `;
export type TranslateMenuCardMutationFn = Apollo.MutationFunction<TranslateMenuCardMutation, TranslateMenuCardMutationVariables>;

/**
 * __useTranslateMenuCardMutation__
 *
 * To run a mutation, you first call `useTranslateMenuCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateMenuCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateMenuCardMutation, { data, loading, error }] = useTranslateMenuCardMutation({
 *   variables: {
 *      locationSlug: // value for 'locationSlug'
 *   },
 * });
 */
export function useTranslateMenuCardMutation(baseOptions?: Apollo.MutationHookOptions<TranslateMenuCardMutation, TranslateMenuCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TranslateMenuCardMutation, TranslateMenuCardMutationVariables>(TranslateMenuCardDocument, options);
      }
export type TranslateMenuCardMutationHookResult = ReturnType<typeof useTranslateMenuCardMutation>;
export type TranslateMenuCardMutationResult = Apollo.MutationResult<TranslateMenuCardMutation>;
export type TranslateMenuCardMutationOptions = Apollo.BaseMutationOptions<TranslateMenuCardMutation, TranslateMenuCardMutationVariables>;
export const UserDocument = gql`
    query User($inviteToken: String, $authToken: String, $resetPasswordToken: String) {
  user(
    inviteToken: $inviteToken
    authToken: $authToken
    resetPasswordToken: $resetPasswordToken
  ) {
    id
    email
    name
    role
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      inviteToken: // value for 'inviteToken'
 *      authToken: // value for 'authToken'
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserUpdateInput!) {
  updateUser(user: $user) {
    id
    email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($user: UserLoginInput!) {
  loginUser(user: $user) {
    name
    email
    companies {
      slug
    }
    role
    authToken
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($user: UserResetPasswordInput!) {
  resetPassword(user: $user)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($user: UserUpdatePasswordInput!) {
  updateUserPassword(user: $user) {
    name
    email
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
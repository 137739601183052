// NB: Keep in sync with the one in the Guest-App

import { useRouter } from 'next/router';
import React, {
  createContext,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { useLocationSettingsQuery } from '../../src/generated/graphql';

// Tbd: convert from graphql enum
export type SupportedLanguagesClient = 'de' | 'en' | 'fr' | 'it';

type Settings = {
  languages: SupportedLanguagesClient[]; // SupportedLanguages to lowerCase
  currency: string;
  logo: string;
  theme: any;
};

type LocationSettingsContextType = {
  refetch: () => void;
} & Settings;

const defaultContext = {
  languages: [],
  currency: '',
  logo: '',
  theme: '',
  refetch: () => {},
};

type LocationSettingsContextProvider = {
  children: React.ReactNode;
};

const CreateLocationSettingsContext = () => {
  return createContext<LocationSettingsContextType>(defaultContext);
};

type LocationSettingsContextProps = {
  context: React.Context<LocationSettingsContextType>;
} & HTMLAttributes<HTMLDivElement>;

export const LocationSettingsContextProvider: React.FC<
  LocationSettingsContextProps
> = ({ children, context }) => {
  const router = useRouter();
  const locationSlug = router.query.locationSlug as string;

  const locationSettingsQuery = useLocationSettingsQuery({
    variables: {
      locationSlug: locationSlug || '',
    },
  });

  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    if (!locationSettingsQuery.data?.locationSettings) return;

    const { languages, currency, logo, theme } =
      locationSettingsQuery.data.locationSettings;

    // Format languages to lowerCase as keys in language objects are lowerCase
    const languagesToLowerCase = languages?.map(
      (language) => language.toLowerCase() as SupportedLanguagesClient
    );

    setSettings({
      languages: languagesToLowerCase || [],
      currency: currency || '',
      logo: logo || '',
      theme,
    });
  }, [locationSettingsQuery.data?.locationSettings]);

  const contextValue: LocationSettingsContextType =
    {
      languages: settings?.languages || [],
      currency: settings?.currency || '',
      logo: settings?.logo || '',
      theme: settings?.theme || '',
      refetch: locationSettingsQuery.refetch,
    } || defaultContext;

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const locationSettingsContext = CreateLocationSettingsContext();

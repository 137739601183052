const yellow = {
  50: '#fffadd',
  100: '#fcf1b3',
  200: '#f8e885',
  300: '#f6de56',
  400: '#f3d529',
  500: '#d9bc11',
  600: '#a99209',
  700: '#796804',
  800: '#483f00',
  900: '#1a1500',
};

const grey = {
  50: '#f2f2f2',
  100: '#d9d9d9',
  200: '#bfbfbf',
  300: '#a6a6a6',
  400: '#8c8c8c',
  500: '#737373',
  600: '#595959',
  700: '#404040',
  800: '#262626',
  900: '#0d0d0d',
};

export const colors = {
  yellow,
  grey,
};

// NB: Keep in sync with the one in the Guest-App

import { useRouter } from 'next/router';
import React, {
  createContext,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import {
  CartType,
  useLocationPermissionsQuery,
} from '../../src/generated/graphql';

type Permissions = {
  canCallWaiter?: boolean;
  hasPayment?: boolean;
  hasPos?: boolean;
  canDirectSendToPos?: boolean;
  canAutoTranslate?: boolean;
  canOrder?: boolean;
};

type LocationPermissionsContextType = {
  refetch?: () => void;
  loading?: boolean;
} & Permissions;

const CreateLocationPermissionsContext = () =>
  createContext<LocationPermissionsContextType>({});

type LocationPermissionsContextProps = {
  context: React.Context<LocationPermissionsContextType>;
} & HTMLAttributes<HTMLDivElement>;

export const LocationPermissionsContextProvider: React.FC<
  LocationPermissionsContextProps
> = ({ children, context }) => {
  const router = useRouter();
  const locationSlug = router.query.locationSlug as string;

  const locationPermissionsQuery = useLocationPermissionsQuery({
    variables: {
      locationSlug: locationSlug || '',
    },
  });

  const [permissions, setPermissions] = useState<Permissions>();

  useEffect(() => {
    if (!locationPermissionsQuery.data?.locationPermissions) return;

    const {
      hasWaiterCall,
      hasPayment,
      hasPos,
      canDirectSendToPos,
      cartType,
      canAutoTranslate,
    } = locationPermissionsQuery.data.locationPermissions;

    setPermissions({
      canCallWaiter: hasWaiterCall,
      hasPayment,
      hasPos,
      canDirectSendToPos,
      canAutoTranslate,
      canOrder: cartType === CartType.Order,
    });
  }, [locationPermissionsQuery.data?.locationPermissions]);

  const contextValue: LocationPermissionsContextType = {
    canCallWaiter: permissions?.canCallWaiter,
    hasPayment: permissions?.hasPayment,
    hasPos: permissions?.hasPos,
    canOrder: permissions?.canOrder,
    canDirectSendToPos: permissions?.canDirectSendToPos,
    canAutoTranslate: permissions?.canAutoTranslate,
    refetch: locationPermissionsQuery.refetch,
    loading: locationPermissionsQuery.loading,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const locationPermissionsContext = CreateLocationPermissionsContext();

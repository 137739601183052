import { extendTheme } from '@chakra-ui/react';
import { buttonStyles } from './button';
import { colors } from './colors';
import { formLabelStyles } from './formLabelStyles';

const styles = {
  global: {
    body: {
      minHeight: '100vh',
    },
  },
};

const theme = extendTheme({
  colors,
  styles,
  components: {
    Button: buttonStyles,
    FormLabel: formLabelStyles,
  },
});

export default theme;

import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import client from '../apollo-client';
import { ChakraProvider } from '@chakra-ui/react';
import { appWithTranslation } from 'next-i18next';
import theme from '../toolkit/theme';
import {
  LocationSettingsContextProvider,
  locationSettingsContext,
} from '../toolkit/contexts/LocationSettingsContext';
import {
  locationPermissionsContext,
  LocationPermissionsContextProvider,
} from '../toolkit/contexts/LocationPermissionsContext';
import Head from 'next/head';

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps) {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <LocationPermissionsContextProvider
            context={locationPermissionsContext}
          >
            <LocationSettingsContextProvider context={locationSettingsContext}>
              <Component {...pageProps} />
            </LocationSettingsContextProvider>
          </LocationPermissionsContextProvider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
